import { Component, EventEmitter, OnInit, Output } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { CryptoService } from '../../../../shared/utilities/services/crypto.services';
import { MatDialog } from '@angular/material/dialog';
import { RecoverPassComponent } from '../recover-pass/recover-pass.component';

@Component({
  selector: 'app-login-form',
  templateUrl: './login-form.component.html',
  styleUrls: ['./login-form.component.scss']
})
export class LoginFormComponent implements OnInit {
  @Output() sendLoginForm = new EventEmitter<any>();
  public form: FormGroup;
  public usuarioTmp = {usuario: '', contrasenia: ''};

  constructor(private _cryptoService: CryptoService,
              public dialog: MatDialog) { }

  public ngOnInit(): void {
    this.form = new FormGroup({
      //usuario: new FormControl(this.usuarioTmp, [Validators.required, Validators.email]),
      usuario: new FormControl('', [Validators.required]),
      contrasenia: new FormControl('', [Validators.required])
    });
  }
  
  public transforma() {
    let objForm = this.form.getRawValue();    
    this.usuarioTmp.usuario = this._cryptoService.crypData(objForm.usuario);
    this.usuarioTmp.contrasenia = this._cryptoService.crypData(objForm.contrasenia);
  }

  public login(): void {
    if (this.form.valid) {
      this.transforma();
      this.form.reset();
      this.sendLoginForm.emit(this.usuarioTmp);
    }
  }

  openDialog(): void {
    const dialogRef = this.dialog.open(RecoverPassComponent, {
      width: '400px',
      data: {}
    });

    dialogRef.afterClosed().subscribe(result => {});
  }
}
