import { Paginador } from "../../../../../shared/utilities/models";

export class AlertaFiltro {
    constructor(
        public columnaOrden: string = '',
        public datPag: Paginador = new Paginador(),

        public filTitulo: string = '',
        public filDescripcion: string = '',
        public filtraFilEsParaTodos: boolean = false,
        public filEsParaTodos: boolean = false,
        public filFechaInicio: string = '0001-01-01',
        public filFechaFin: string = '0001-01-01',
    ) { }
}