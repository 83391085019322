<mat-card class="video-wrapper">
    <mat-card-title class="video-wrapper__header">
        <p>
            {{ paramVideoApp.titulo }}
        </p>
    </mat-card-title>
    <mat-card-content class="">
        <div #videoAppDiv>

            <div *ngIf="paramVideoApp.tipoVideo == 1">
                <iframe [src]="paramVideoApp.idVideo | vimeoUrl" [height]="heightVideo" [width]="widthVideo" frameborder="0" allow="autoplay; fullscreen; picture-in-picture" allowfullscreen></iframe>
            </div>

            <div *ngIf="paramVideoApp.tipoVideo == 2">
                <youtube-player [videoId]="paramVideoApp.idVideo" suggestedQuality="highres" [height]="heightVideo" [width]="widthVideo">
                </youtube-player>
            </div>

            <div *ngIf="paramVideoApp.tipoVideo == 3">
                <iframe [src]="paramVideoApp.idVideo | googleDriveUrl" [height]="heightVideo" [width]="widthVideo" frameborder="0" allow="autoplay; fullscreen;" allowfullscreen></iframe>
            </div>

        </div>
    </mat-card-content>
</mat-card>