import { Injectable } from '@angular/core';
import { Usuario } from '../../../pages/base/catalogs/administration/usuarios/models';

@Injectable()
export class LocalStorageService {
    constructor() { }

    public estableceUsuarioSesion(usuario: Usuario): void {
        localStorage.setItem('DW_usuario_sesion', JSON.stringify(usuario));
    }

    public estableceTokenSesion(token: string): void {
        localStorage.setItem('DW_token_api', JSON.stringify(token));
    }

    public obtenerTokenSesion(): string {
        let token = JSON.parse(localStorage.getItem('DW_token_api'));

        return token == null ? null : token;
    }

    public esSesionActiva(): boolean {
        return localStorage.getItem('DW_token_api') && localStorage.getItem('DW_usuario_sesion') ? true : false;
    }

    public terminarSesion() {
        localStorage.removeItem('DW_token_api');
        localStorage.removeItem('DW_usuario_sesion');
    }

    public obtenerUsuarioSesion(): Usuario {
        let usuarioSesion: Usuario = JSON.parse(localStorage.getItem('DW_usuario_sesion'));

        return usuarioSesion == null ? null : usuarioSesion;
    }
}
