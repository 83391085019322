import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root'
})
export class FuncionesArreglosService {

  constructor() {}

  arregloTarjetas(arreglo: any[]): any[] {
    const LONGITUD = 4;
    let arrMultiple = [];

    for (let i = 0; i < arreglo.length; i += LONGITUD) {
      const arrDivide = arreglo.slice(i, i + LONGITUD);
      arrMultiple.push(arrDivide);
    }

    for (let j = 0; j < arrMultiple.length; j++) {
      if ((arrMultiple[j].length % LONGITUD) != 0) {
        let cont = LONGITUD - (arrMultiple[j].length % LONGITUD);
        for (let k = 1; k <= cont; k++) {
          arrMultiple[j].push({});
        }
      }
    }

    return arrMultiple;
  }

}

    