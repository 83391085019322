import { Component, ElementRef, OnInit, OnDestroy, ViewChild, EventEmitter, Output, Input } from '@angular/core';

@Component({
  selector: 'app-carga-archivo',
  templateUrl: './carga-archivo.component.html',
  styleUrls: ['./carga-archivo.component.scss']
})
export class CargaArchivoComponent implements OnInit, OnDestroy {
  @Input() archivoAceptado: string;
  @Output() eventoArchivo: EventEmitter<any> = new EventEmitter<any>();
  @ViewChild('fileInput') fileInput: ElementRef;
  public fileAttr = 'Archivo...';
  public tipoArchivo: string;

  constructor() { }

  ngOnInit() {
    if (this.archivoAceptado === 'xls') { //EXCEL
      this.tipoArchivo = '.xlsm, application/vnd.ms-excel, application/vnd.openxmlformats-officedocument.spreadsheetml.sheet';
    } else if (this.archivoAceptado === 'pdf') { //PDF
      this.tipoArchivo = '.pdf';
    } else if (this.archivoAceptado === 'zip') { //VIDEO
      this.tipoArchivo = '.zip, .7z';
    } else if (this.archivoAceptado === 'mp4') { //VIDEO
      this.tipoArchivo = 'video/*';
    } else if (this.archivoAceptado === 'img') { //IMAGEN
      this.tipoArchivo = 'image/*';
    } else if (this.archivoAceptado === 'txt') { //TEXTO
      this.tipoArchivo = 'text/plain';
    } else if (this.archivoAceptado === 'doc') { //TEXTO
      this.tipoArchivo = '.doc, .docx';
    } else if (this.archivoAceptado === 'satcer') { //TEXTO
      this.tipoArchivo = '.cer';
    } else if (this.archivoAceptado === 'satkey') { //TEXTO
      this.tipoArchivo = '.key';
    }
  }

  ngOnDestroy() { }

  cargaArchivo(evtArchivo: any) {
    this.fileAttr = '';

    if (evtArchivo.target.files && evtArchivo.target.files.length > 0) {
      this.fileAttr = evtArchivo.target.files[0].name;
      const file = evtArchivo.target.files[0];
      //this.fileInput.nativeElement.value = "";
      this.eventoArchivo.emit(file)
    } else {
      this.fileAttr = 'No se cargo archivo...';
      this.eventoArchivo.emit(undefined);
    }
  }

  /* uploadFileEvt(imgFile: any) {
    if (imgFile.target.files && imgFile.target.files.length > 0) {
      this.fileAttr = '';
      Array.from(imgFile.target.files).forEach((file: File) => {
        this.fileAttr += file.name + ' - ';
      });

      // HTML5 FileReader API
      let reader = new FileReader();
      reader.onload = (e: any) => {
        let image = new Image();
        image.src = e.target.result;
        image.onload = rs => {
          let imgBase64Path = e.target.result;
        };
      };
      reader.readAsDataURL(imgFile.target.files[0]);

      // Reset if duplicate image uploaded again
      this.fileInput.nativeElement.value = "";
    } else {
      this.fileAttr = 'Choose File';
    }
  } */
}
