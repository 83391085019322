import { DatosAuditoria } from '../../../shared/utilities/models/datos-auditoria';

export class UsuarioPago extends DatosAuditoria {
    constructor (
        public usuarioId: number = 0,
        public paypalId: number = 0,
        public fechaExpiracion: string = '1800-01-01',
        public contraseniaIni: string = '',
        public transaccionId: string = '',
        public intent: string = '',
        public codigoPais: string = '',
        public correo: string = '',
        public payerId: string = '',
        public fechaCreacionPayPal: string = '1800-01-01',
        public fechaModificacionPayPal: string = '1800-01-01',
        public estatus: string = '',
        public descripcion: string = '',
        public costo: number = 0,
        public moneda: string = '',
        public tipoPagoId: number = 0,
        public tipoPlanId: number = 0,
        public usuarioNombre: string = '',
        public contrasenia: string = ''
    ) {
        super();
    }
}