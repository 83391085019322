import { CollectionViewer, DataSource } from "@angular/cdk/collections";
//Rxjs
import { Observable, BehaviorSubject, of } from "rxjs";
import { catchError, finalize } from "rxjs/operators";
//Shared
import { FuncionesArreglosService } from '../../../../../shared/utilities/services'
//App
import { Curso, CursoFiltro } from "../models";
import { CursoService } from "./curso.service";

export class CursoDataSourceService implements DataSource<Curso> {
    private cursoSubject = new BehaviorSubject<Curso[]>([]);
    public cursos$ = this.cursoSubject.asObservable();

    constructor() { }

    connect(collectionViewer: CollectionViewer): Observable<Curso[]> {
        return this.cursoSubject.asObservable();
    }

    disconnect(collectionViewer: CollectionViewer): void {
        this.cursoSubject.complete();
    }

    cargaCursos(filtro: CursoFiltro, service: CursoService) {
        service.cursosPag(filtro)
            .pipe(
                catchError(() => of([])),
                //finalize(() => this.loadingSubject.next(false))
            )
            .subscribe(data => {
                this.actualizaDatos(filtro, data);
            });
    }

    actualizaDatos(filtro: CursoFiltro, pagina: any) {
        this.cursoSubject.next(pagina['pagina'])
        filtro.datPag = pagina['datPag'];
    }

    cargaTarjetas(filtro: CursoFiltro, service: CursoService, fnArrService: FuncionesArreglosService) {
        service.cursosPag(filtro)
            .pipe(
                catchError(() => of([])),
                //finalize(() => this.librosSubject.next())
            )
            .subscribe(data => {                
                this.actualizaTarjetas(filtro, data, fnArrService);
            });
    }

    actualizaTarjetas(filtro: CursoFiltro, pagina: any, fnArrService: FuncionesArreglosService) {
        let arrMulti = fnArrService.arregloTarjetas(pagina['pagina']);
        
        this.cursoSubject.next(arrMulti);
        filtro.datPag = pagina['datPag'];        
    }
}