import { NgModule, CUSTOM_ELEMENTS_SCHEMA } from '@angular/core';
import { RouteReuseStrategy, RouterModule } from '@angular/router';
import { BrowserModule } from '@angular/platform-browser';
import { HttpClientModule, HTTP_INTERCEPTORS } from '@angular/common/http';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';

import { AppComponent } from './app.component';
import { AppRoutingModule } from './app-routing.module';

//Material
import { ToastrModule } from 'ngx-toastr';
import { NgxSpinnerModule } from 'ngx-spinner';
import { MatCardModule } from '@angular/material/card';
import { MatButtonModule } from '@angular/material/button';
//Video
import { VimeModule } from '@vime/angular';
//Paypal
import { NgxPayPalModule } from 'ngx-paypal';
//App
import { AuthModule } from './pages/auth/auth.module';
import { SharedModule } from './shared/shared.module';
import { PresentationModule } from "./pages/business/presentation/presentation.module";
import { NotFoundComponent } from './pages/not-found/not-found.component';
import { CustomReuseStrategy } from './shared/utilities/services/custom-reuse-strategy';
import { AuthInterceptor } from './shared/utilities/interceptors/auth-interceptor';
import { LocalStorageService } from './shared/utilities/services/local-storage.service';


@NgModule({
  declarations: [
    AppComponent,
    NotFoundComponent    
  ],
  imports: [
    BrowserModule,
    HttpClientModule,
    SharedModule,
    AuthModule,
    PresentationModule,
    BrowserAnimationsModule,
    RouterModule,
    AppRoutingModule,
    ToastrModule.forRoot(),
    NgxSpinnerModule,
    MatCardModule,
    MatButtonModule,
    VimeModule,
    NgxPayPalModule
  ],
  schemas: [
    CUSTOM_ELEMENTS_SCHEMA
  ],
  providers: [
    {
      provide: HTTP_INTERCEPTORS,
      useClass: AuthInterceptor,
      multi: true
    },
    {
      provide: RouteReuseStrategy,
      useClass: CustomReuseStrategy      
    },
    LocalStorageService
  ],
  bootstrap: [AppComponent]
})
export class AppModule { }
