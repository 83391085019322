import { ChangeDetectionStrategy, Component, HostListener, Inject } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from "@angular/material/dialog";

@Component({
  changeDetection: ChangeDetectionStrategy.OnPush,
  selector: 'app-mensaje',
  templateUrl: './mensaje.component.html',
  styleUrls: ['./mensaje.component.scss']
})
export class MensajeComponent {
  constructor(
    @Inject(MAT_DIALOG_DATA) 
    public data: {
      titulo: string,
      mensaje: string,
      btnAceptar: string
    },
    private mdDialogRef: MatDialogRef<MensajeComponent>
  ) { }
  
  public cancelar() {
    this.cerrar(false);
  }

  @HostListener("keydown.esc")
  public onEsc() {
    this.cerrar(false);
  }

  public cerrar(value: any) {
    this.mdDialogRef.close(value);
  }
}
