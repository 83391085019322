import { Component, EventEmitter, Input, Output, OnInit } from '@angular/core';

import { routes } from '../../../../consts';
import { Usuario } from '../../../../pages/base/catalogs/administration/usuarios/models';
import { Router } from '@angular/router';
import { LocalStorageService } from '../../../utilities/services/local-storage.service';

@Component({
  selector: 'app-user',
  templateUrl: './user.component.html',
  styleUrls: ['./user.component.scss']
})
export class UserComponent implements OnInit {
  @Input() usuarioSesion: Usuario;
  @Output() terminarSesion: EventEmitter<void> = new EventEmitter<void>();
  
  public routes: typeof routes = routes;
  public usuarioLog: Usuario;

  constructor(
    private _router: Router,
    private _localStorageService: LocalStorageService
  ) { }

  ngOnInit() {
    this.usuarioLog = this._localStorageService.obtenerUsuarioSesion();
  }

  public terminarSesionEmit(): void {
    this.terminarSesion.emit();
  }

  public datosUsuario() {
    this._router.navigate([routes.CONTRASENIA, this.usuarioLog.usuarioId]);
  }
}
