import { Injectable } from '@angular/core';
import { MatDialog, MatDialogRef } from '@angular/material/dialog';
import { Observable } from 'rxjs';
import { map, take } from 'rxjs/operators';
import { MensajeConfirmacionComponent } from './mensaje-confirmacion.component';

@Injectable({
  providedIn: 'root'
})
export class MensajeConfirmacionService {
  dialogRef: MatDialogRef<MensajeConfirmacionComponent>;

  constructor(private dialog: MatDialog) { }

  public open(options: any) {
    this.dialogRef = this.dialog.open(MensajeConfirmacionComponent, {
      data: {
        titulo: options.titulo,
        mensaje: options.mensaje,
        btnCancelaTexto: options.btnCancelaTexto,
        btnConfirmaTexto: options.btnConfirmaTexto
      }
    });
  }

  public confirmed(): Observable<any> {
    return this.dialogRef
      .afterClosed()
      .pipe(
        take(1), map(res => {
          return res;
        })
      );
  }
}
