import { DatosAuditoria } from '../../../../../shared/utilities/models';

export class AlertaUsuario extends DatosAuditoria {
    constructor (
        public alertaId: number = 0,
        public usuarioId: number = 0,
        public leido: boolean = false,
    ) {
        super();
    }
}