<div class="auth-page">
    <div class="auth-page__content-block">
        <div class="auth-page__content-wrapper">

            <mat-tab-group class="auth-page__group" (selectedTabChange)="muestraPaypal($event)">
                <!-- Login -->
                <mat-tab label="Acceso">
                    <h4 class="auth-page__group-title">Bienvenido!</h4>

                    <app-login-form (sendLoginForm)="sendLoginForm($event)"></app-login-form>

                    <mat-error *ngIf="errorLogin != ''">
                        {{ errorLogin }}
                    </mat-error>

                </mat-tab>
                <!-- Subscripción -->
                <mat-tab label="Suscripción">
                    <h4 class="auth-page__group-title">Bienvenido!</h4>
                    <p class="auth-page__group-sub-title">Suscríbete a nuestra plataforma Contaclub.</p>

                    <!-- <app-sign-form (sendSignForm)="sendSignForm($event)"></app-sign-form> -->

                    <!-- <div *ngIf="tipoPago == 1" class="auth-page__group-paypal">
                        <ngx-paypal [config]="payPalConfigSub"></ngx-paypal>
                    </div>

                    <div *ngIf="tipoPago == 2" class="auth-page__group-paypal">
                        <ngx-paypal [config]="payPalConfigCont"></ngx-paypal>
                    </div> -->

                    <!-- <ngx-paypal [config]="payPalConfigCont"></ngx-paypal> -->
                    <ngx-paypal [config]="payPalConfigSub"></ngx-paypal>
                    <p *ngIf="pagoExito != ''" class="auth-page__group-success">{{ pagoExito }}</p>
                    <p *ngIf="pagoError != ''" class="auth-page__group-error">{{ pagoError }}</p>

                    <!-- <p class="auth-page__group-sub-title">No cuentas con tarjeta? Descarga nuestro formato de pago Contaclub.</p>

                    <div class="auth-page__formatos">
                        <button mat-raised-button color="warn" type="button" (click)="descarga()">Descargar formatos</button>
                    </div>

                    <div class="auth-page__pagos">
                        <img src="../../../../../assets/img/oxxo.png" class="auth-page__pagos-oxxo" />
                        <img src="../../../../../assets/img/santander.png" class="auth-page__pagos-sant" />
                    </div>

                    <div class="auth-page__mercadopagos">
                        <p class="auth-page__mercadopagos-title">SUSCRIPCIÓN A MI DESPACHO EN LA NUBE.</p>
                        <a mat-raised-button href="https://www.mercadopago.com.mx/subscriptions/checkout?preapproval_plan_id=2c9380847ac5c248017ad55884190e8d" target="_blank" class="auth-page__mercadopagos-a">
                            Suscripción mensual $150 mi despacho en la nube
                        </a>
                    </div> -->

                    <p class="auth-page__mail">Si tienes problema con tu pago, escribenos a valdezsantanaconsultoria@gmail.com.</p>

                </mat-tab>
                <!--<mat-tab label="Mercado">
                    <div class="cho-container">PRUEBAS</div>
                    <script>
                        const mp = new MercadoPago('TEST-60bfe910-ebc9-4879-94fe-da95c822723b', {
                            locale: 'en-MX'
                        });

                        mp.checkout({
                            preference: {
                            id: 'Mi producto'
                            },
                            render: {
                            container: '.cho-container',
                            label: 'Pago',
                            }
                        });
                    </script>
                </mat-tab>-->
            </mat-tab-group>
            <!--<p class="auth-page__footer-title">© 2014-{{todayDate | year}} <a href="https://flatlogic.com/">Flatlogic</a>, LLC. All rights reserved.</p>-->

        </div>
    </div>
    <div class="auth-page__logo">

        <div class="auth-page__logo-wrapper">
            <img class="auth-page__logo-img" src="./assets/auth/logocc.png" alt="logo">
            <!-- <h6 class="auth-page__logo-title">J & R Asesores Contables</h6> -->
            <p class="auth-page__text">
                Si ya perteneces a la comunidad CONTACLUB inicia sesión. Si aún no perteneces a CONTACLUB y quieres hacerlo, pulsa en suscripción y te lo explicamos todo.
            </p>
            <p class="auth-page__text">
                ¡Te vemos dentro de CONTACLUB!
            </p>
        </div>

    </div>
</div>