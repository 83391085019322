import { Injectable } from '@angular/core';
import { MatDialog, MatDialogRef } from '@angular/material/dialog';
import { MensajeComponent } from './mensaje.component';

@Injectable({
  providedIn: 'root'
})
export class MensajeService {
  dialogRef: MatDialogRef<MensajeComponent>;

  constructor(private dialog: MatDialog) { }

  public open(options: any) {
    this.dialogRef = this.dialog.open(MensajeComponent, {
      data: {
        titulo: options.titulo,
        mensaje: options.mensaje,
        btnAceptar: options.btnAceptar
      }
    });
  }
}
