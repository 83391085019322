<form class="form" [formGroup]="form" (ngSubmit)="recover()">
    <h6>Ingrese correo con que se registro.</h6>
    <mat-form-field class="form__input">
        <input matInput placeholder="Correo" type="text" formControlName="correoUsuario">
    </mat-form-field>

    <div class="form-actions">
        <button class="form-actions__login" mat-raised-button color="warn" type="submit">Recuperar clave</button>
        <br />
    </div>
</form>
<br />
<p color="warn">
    {{ error }}
</p>