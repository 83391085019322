import { Paginador } from "../../../../../shared/utilities/models";
import { Curso } from "./curso";

export class CursoPagina {
    constructor(
        public pagina: Curso[] = [],
        public datPag: Paginador = new Paginador()
        //campoTotales1: number
        //campoTotales2: number
    ) { }
}