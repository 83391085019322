import { Component, OnInit, ViewChild, OnDestroy, AfterViewInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
//rxjs
import { tap } from 'rxjs/operators';
//Material
import { MatPaginator } from '@angular/material/paginator';
import { MatSort } from '@angular/material/sort';
//Shared
import { FuncionesTextoService } from '../../../../../../shared/utilities/services'
//App
import { routes } from "../../consts";
import { Curso, CursoFiltro } from '../../models';
import { CursoService, CursoDataSourceService } from '../../services';
import { MensajeConfirmacionService } from '../../../../../../shared/utilities/components/mensaje-confirmacion/mensaje-confirmacion.service';
import { MensajeService } from '../../../../../../shared/utilities/components/mensaje/mensaje.service';

@Component({
  selector: 'app-curso-consulta',
  templateUrl: './curso-consulta.component.html',
  styleUrls: ['./curso-consulta.component.scss']
})
export class CursoConsultaComponent implements OnInit, OnDestroy, AfterViewInit {
  public error: string = '';
  public cursoFiltro = new CursoFiltro();  
  public dataSourceService: CursoDataSourceService;
  public displayedColumns: string[] = ['tipoCursoId', 'cursoId', 'cursoNombre', 'titulo', 'descripcion', 'anio', 'activo', 'consultar', 'editar', 'eliminar'];
  public opcMsj = { titulo: '', mensaje: '', btnAceptar: '' };
  public opcMsjCon = { titulo: '', mensaje: '', btnCancelaTexto: '', btnConfirmaTexto: '' };

  @ViewChild(MatPaginator, { static: true }) paginator: MatPaginator;
  @ViewChild(MatSort) sort: MatSort;

  constructor(
    private _router: Router,
    private _route: ActivatedRoute,
    private _cursoService: CursoService,
    private _mensajeService: MensajeService,
    private _mensajeConfirmacionService: MensajeConfirmacionService,
    private _fnTextoService: FuncionesTextoService
  ) { }

  ngOnInit(): void {
    this.dataSourceService = new CursoDataSourceService();
    let pagina: any = this._route.snapshot.data["dataSourceService"];    
    if (pagina['error']) {
      this.error = 'Ha ocurrio un error en el servidor, intente nuevamente.';
    } else if (pagina.pagina) {
      if (pagina.pagina.length > 0) {
        this.dataSourceService.actualizaDatos(this.cursoFiltro, pagina);
      } else {
        this.error = 'No existen registros a mostrar.';
      }
    } else {
      this.error = 'Ha ocurrio un error en el servidor, intente nuevamente.';
    }
  }

  ngOnDestroy(): void {
    this.dataSourceService = null;
  }

  ngAfterViewInit() {
    this.sort.sortChange.subscribe((event) => {
      let campoOrden = this._fnTextoService.capitalizaPrimeraLetra(event.active);
      this.paginator.pageIndex = 1;
      this.cursoFiltro.columnaOrden = (event.direction === 'desc') ? `-${campoOrden}` : campoOrden;
      this.cargaCursoPagina();
    });

    this.paginator.page
      .pipe(
        tap((event) => {
          this.cursoFiltro.datPag.currentPage = event.pageIndex + 1;
          this.cursoFiltro.datPag.pageSize = event.pageSize;
          this.cursoFiltro.datPag.pageSizePrev = event.previousPageIndex;
          this.cursoFiltro.datPag.totalLines = event.length;
          this.cargaCursoPagina();
        })
      )
      .subscribe();
  }

  cargaCursoPagina() {
    this.dataSourceService.cargaCursos(
      this.cursoFiltro,
      this._cursoService
    );
  }

  filtrar(cursoFiltro: CursoFiltro) {
    this.cursoFiltro = cursoFiltro;
    this.cargaCursoPagina();
  }

  agregar() {
    this._router.navigate([routes.CAPTURA, 0, 1]);
  }

  editar(curso: Curso) {
    this._router.navigate([routes.CAPTURA, curso.cursoId, 2]);
  }

  consultar(curso: Curso) {
    this._router.navigate([routes.CAPTURA, curso.cursoId, 3]);
  }

  iniciaEliminar(curso: Curso) {
    this.opcMsjCon = {
      titulo: 'Eliminar registro',
      mensaje: '¿Desea eliminar el registro?',
      btnCancelaTexto: 'Cancelar',
      btnConfirmaTexto: 'Confirmar'
    };

    this._mensajeConfirmacionService.open(this.opcMsjCon);

    this._mensajeConfirmacionService.confirmed().subscribe(confirmed => {
      if (confirmed) {
        this.eliminar(curso);
      }
    });
  }

  eliminar(curso: Curso) {
    this.opcMsj = {
      titulo: 'Eliminar',
      mensaje: 'Operación exitosa.',
      btnAceptar: 'Cerrar'
    };

    this._cursoService.cursoElimina(curso).subscribe(
      result => {
        if (result === true) {
          this._mensajeService.open(this.opcMsj);
          this.cargaCursoPagina();
        }
      },
      error => {
        this.opcMsj.mensaje = error.error
        this._mensajeService.open(this.opcMsj);
      },
    );
  }
}
