import { DatosAuditoria } from '../../../../../shared/utilities/models';
import { TipoVideoCurs } from './enumeraciones'

export class Curso extends DatosAuditoria {
    constructor (
        public tipoCursoId: number = 0,
        public cursoId: number = 0,
        public orden: number = 0,
        public cursoNombre: string = '',
        public titulo: string = '',
        public descripcion: string = '',
        public linkCurso: string = '',
        public linkMaterialCurso: string = '',
        public tipoVideo: TipoVideoCurs = TipoVideoCurs.Ninguno,
        public anio: number = 0,
        public activo: boolean = true,        
    ) {
        super();
    }
}