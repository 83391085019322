import { AfterViewInit, ChangeDetectorRef, Component, ElementRef, OnInit, OnDestroy, ViewChild, Input } from '@angular/core';
import { VideoApp } from '../../models/video-app';

@Component({
  selector: 'app-vime-app',
  templateUrl: './vime-app.component.html',
  styleUrls: ['./vime-app.component.scss']
})
export class VimeAppComponent implements AfterViewInit, OnInit, OnDestroy {
  @Input() paramVideoApp: VideoApp;
  @ViewChild('videoAppDiv') videoAppDiv: ElementRef<HTMLDivElement>;
  public heightVideo: number;
  public widthVideo: number;

  constructor(private _changeDetectorRef: ChangeDetectorRef) { }

  ngOnInit() { }

  ngAfterViewInit(): void {
    this.onResize();
    window.addEventListener('resize', this.onResize);
  }

  onResize = (): void => {
    // Amplia automáticamente el video para que se ajuste a la página hasta 1200px x 720px
    this.widthVideo = Math.min(this.videoAppDiv.nativeElement.clientWidth, 1200);
    this.heightVideo = this.widthVideo * 0.5;
    this._changeDetectorRef.detectChanges();
  }

  ngOnDestroy(): void {
    window.removeEventListener('resize', this.onResize);
  }
}
