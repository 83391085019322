<mat-card class="video-wrapper">
    <mat-card-title class="video-wrapper__header">
        <p>
            {{ paramVideoApp.titulo }}
        </p>
    </mat-card-title>
    <mat-card-content class="video__content">
        <div #videoAppDiv>

            <!-- <vm-player>
                <vm-video cross-origin="true" poster="https://media.vimejs.com/poster.png">
                    <source data-src="{{ paramVideoApp.idVideo }}" type="video/mp4" />
                    <track default kind="subtitles" src="https://media.vimejs.com/subs/english.vtt" srclang="en" label="English" />
                    <track kind="subtitles" src="https://media.vimejs.com/subs/spanish.vtt" srclang="es" label="Spanish" />
                </vm-video>

                <vm-default-ui>
                </vm-default-ui>
            </vm-player> -->

            <video id="elemVideo" class="video-js" controls preload="auto">
                <p class="vjs-no-js">  
                  To view this video please enable JavaScript, and consider upgrading to a
                  web browser that
                  <a href="http://videojs.com/html5-video-support/" target="_blank">
                    supports HTML5 video
                  </a>
                </p>
              </video>

        </div>
    </mat-card-content>
</mat-card>