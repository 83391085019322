export enum routes {
  //Rutas
  CONSULTA = 'cat-alertas/listado',
  CAPTURA = 'cat-alertas/captura/',  
  //Acciones
  URL_BASE = '/PriCursos/Alertas/',
  URL_CONSULTA_PAGINADA = 'AlertaPag',
  URL_CONSULTA_X_USUARIO = 'AlertaXUsuarioId',
  URL_CONSULTA_X_ID = 'AlertaXId',
  URL_COMBO = 'AlertaCmb',
  URL_INSERTA = 'AlertaInserta',
  URL_ACTUALIZA = 'AlertaActualiza',
  URL_ELIMINA = 'AlertaElimina',
  URL_REVISADA = 'AlertaUsuarioInserta',
}
