import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
//rxjs
import { Observable } from 'rxjs';
import { concatMap, map, switchMap, tap } from 'rxjs/operators';
//App
import { url_bases } from "../../../../../consts";
import { routes } from '../consts';
import { CursoPagina, CursoFiltro, Curso } from '../models';

@Injectable({
  providedIn: 'root'
})
export class CursoService {

  constructor(private _httpClient: HttpClient) {}

  public cursosPag(cursoFiltro: CursoFiltro): Observable<CursoPagina> {
    return this._httpClient.post<CursoPagina>(
      url_bases.URL_CLIENTEAPI + routes.URL_BASE + routes.URL_CONSULTA_PAGINADA,
      cursoFiltro
    );
  }

  public cursoXId(id: number): Observable<Curso> {
    return this._httpClient.get<Curso>(
      url_bases.URL_CLIENTEAPI + routes.URL_BASE + routes.URL_CONSULTA_X_ID + '/' + id
    );
  }

  public cursoDescargaXId(curso: Curso): any {
    return this._httpClient.get(
      url_bases.URL_SISEGUI2020API + routes.URL_DESCARGA + curso.linkMaterialCurso,
      { responseType: 'blob' }
    ).pipe(
      map((result: any) => {
          return result;
      })
    );
  }

  /* public cursoInserta(curso: Curso): Observable<any> {
    return this._httpClient.post<any>(
      url_bases.URL_CLIENTEAPI + routes.URL_BASE + routes.URL_INSERTA,
      curso
    );
  } */

  public cursoInserta(curso: Curso, archivoCargado: any): Observable<any> {
    //const formData = new FormData();
    //formData.append('archivo', archivoCargado)

    // return this._httpClient.post<any>(
    //   url_bases.URL_CLIENTEAPI + routes.URL_BASE + routes.URL_INSERTA,
    //   curso
    // ).pipe(
    //   concatMap((id: number) => this._httpClient.post(
    //       url_bases.URL_SISEGUI2020API + routes.URL_CARGA + id.toString() + '_' + curso.linkMaterialCurso,
    //       formData
    //     )
    //   )
    // );

    return this.cursoInsertaNuevo(curso)
        .pipe(
          tap((res) => curso.cursoId = res),
          concatMap(() => this.cursoInsertaMaterial(curso, archivoCargado)),
          tap((res) => console.log('OK')),
          concatMap(() => this.cursoActualizaMaterial(curso)),
          tap((res) => console.log('OK')),
        );
  }

  private cursoInsertaNuevo(curso: Curso): Observable<any> {
    return this._httpClient.post(url_bases.URL_CLIENTEAPI + routes.URL_BASE + routes.URL_INSERTA, curso);
  }

  private cursoInsertaMaterial(curso: Curso, archivoCargado: any): Observable<any> {
    const formData = new FormData();
    formData.append('archivo', archivoCargado);
    return this._httpClient.post(url_bases.URL_SISEGUI2020API + routes.URL_CARGA + curso.cursoId.toString() + '_' + curso.linkMaterialCurso, formData)
  }

  public cursoActualizaMaterial(curso: Curso): Observable<any> {
    curso.linkMaterialCurso = curso.cursoId.toString() + '_' + curso.linkMaterialCurso;
    return this._httpClient.post<any>(
      url_bases.URL_CLIENTEAPI + routes.URL_BASE + routes.URL_ACTUALIZA,
      curso
    );
  }

  public cursoActualiza(curso: Curso, archivoCargado: any): Observable<any> {
    if (archivoCargado === undefined) {
      return this._httpClient.post<any>(
        url_bases.URL_CLIENTEAPI + routes.URL_BASE + routes.URL_ACTUALIZA,
        curso
      );
    } else {
      const formData = new FormData();
      formData.append('archivo', archivoCargado);

      return this._httpClient.post<any>(
        url_bases.URL_CLIENTEAPI + routes.URL_BASE + routes.URL_ACTUALIZA,
        curso
      ).pipe(
        switchMap((res: any) => this._httpClient.post(
            url_bases.URL_SISEGUI2020API + routes.URL_CARGA + curso.linkMaterialCurso,
            formData
          )
        )
      );
    }
  }

  public cursoElimina(curso: Curso): Observable<any> {
    return this._httpClient.post<any>(
      url_bases.URL_CLIENTEAPI + routes.URL_BASE + routes.URL_ELIMINA,
      curso
    );
  }
}

