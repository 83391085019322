import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { Observable } from 'rxjs';
import { get } from 'scriptjs';
import { MatDialog } from '@angular/material/dialog';

//Paypal
import { IPayPalConfig, ICreateOrderRequest } from 'ngx-paypal';
//App
import { paypal } from '../../consts'
import { AuthService } from '../../services';
import { routes } from '../../../../consts';
import { LocalStorageService } from '../../../../shared/utilities/services';
import { Usuario } from '../../../base/catalogs/administration/usuarios/models/usuario';
import { UsuarioPago } from '../../models';
import { url_bases } from "../../../../consts";
import { MensajeService } from "../../../../shared/utilities/components/mensaje/mensaje.service";
import { VideoApp } from 'src/app/shared/utilities/models';
import { CursoVideoDialogComponent } from '../../../../pages/business/catalogs/cursos/components';


@Component({
  selector: 'app-auth-page',
  templateUrl: './auth-page.component.html',
  styleUrls: ['./auth-page.component.scss']
})
export class AuthPageComponent implements OnInit {
  //public todayDate: Date = new Date();
  public errorLogin: string = '';
  public pagoExito: string = '';
  public pagoError: string = '';
  public usuarioSesion: Usuario;
  public routers: typeof routes = routes;
  public payPalConfigSub?: IPayPalConfig;
  //public payPalConfigCont?: IPayPalConfig;
  public tipoPago: number = 0;
  public mensaje$: Observable<string>;

  constructor(
    private _authService: AuthService,
    private _localStorage: LocalStorageService,
    private _router: Router,
    private _mensajeService: MensajeService,
    public _dialog: MatDialog
  ) { }

  ngOnInit() {

    this.mensaje$ = this._authService.getMensaje();
    this.mensaje$.subscribe(msj => this.pagoExito = msj);
    this.abrirVideo();
  }

  public sendLoginForm(valuesLogin: any): void {
    this.errorLogin = 'Iniciando aplicación........';
    this._authService.autentifica(valuesLogin).subscribe(
      authToken => {
        if (authToken['usuarioSesion']) {
          this.errorLogin = '';
          this.usuarioSesion = authToken['usuarioSesion'];
          this._localStorage.estableceUsuarioSesion(this.usuarioSesion);
          this._localStorage.estableceTokenSesion(authToken['tokenApis']);

          this._router.navigate([this.routers.INICIO]).then();
        }
      },
      error => {
        this.errorLogin = 'Usuario o contraseña invalidos.'
      }
    );
  }

  public sendSignForm(tipoPago: any) {
    this.tipoPago = parseInt(tipoPago);
  }

  public muestraPaypal(evt: any): void {
    this.pagoExito = '';
    this.pagoError = '';

    if (evt.index === 1) {
      this.initConfigSubscription();
      //this.initConfigContado();
    }
  }

  private initConfigSubscription(): void {
    this.payPalConfigSub = {
      currency: 'MXN',
      clientId: paypal.ID,
      vault: 'true',
      createSubscription: (data, actions) => {
        return actions.subscription.create({
          plan_id: paypal.PLAN_ID
        });
      },
      style: {
        shape: 'rect',
        color: 'blue',
        layout: 'vertical',
        label: 'paypal'
      },
      onApprove: (data, actions) => {
        this._authService.agregarMensaje('Espere un momento la transacción esta siendo aprobada......');

        actions.order.get().then(details => {
          this._authService.agregarMensaje('');
          if (details.status === 'APPROVED') {
            this.guardarUsuario(details);
            alert('Se ha enviado un correo con sus credenciales a: ' + details.payer.email_address + '. Gracias por suscribirse a CONTACLUB!')
          } else {
            this._authService.agregarMensaje('Su transacción fue aprobada pero no autorizada. No se pudo realizar la suscripción.');
          }
        });
      },
      onCancel: (data, actions) => {
        this._authService.agregarMensaje('');
      },
      onError: err => {
        this._authService.agregarMensaje(err);
      },
      onClick: (data, actions) => {
        this._authService.agregarMensaje('Cargando....');
      },
    };
  }

  /* private initConfigContado(): void {
    this.payPalConfigCont = {
      currency: 'MXN',
      clientId: paypal.ID,
      createOrderOnClient: (data) => <ICreateOrderRequest>{
        intent: 'CAPTURE',
        purchase_units: [{
          amount: {
            currency_code: 'MXN',
            value: '499.00',
            breakdown: {
              item_total: {
                currency_code: 'MXN',
                value: '499.00'
              }
            }
          },
          items: [{
            name: 'Plataforma ContaClub',
            quantity: '1',
            category: 'DIGITAL_GOODS',
            unit_amount: {
              currency_code: 'MXN',
              value: '499.00',
            },
          }]
        }]
      },
      advanced: {
        commit: 'true'
      },
      style: {
        label: 'paypal',
        layout: 'vertical'
      },
      onApprove: (data, actions) => {
        this.pagoExito = 'Espere un momento......';
        console.log('onApprove - transaction was approved, but not authorized', data, actions);
        actions.order.get().then(details => {
          console.log('onApprove - you can get full order details inside onApprove: ', details);
        });
      },
      onClientAuthorization: (data) => {
        console.log('onClientAuthorization - you should probably inform your server about completed transaction at this point', data);
        this.guardarUsuario(data);
      },
      onCancel: (data, actions) => {
        this.pagoError = 'La transacción se ha cancelado';
        console.log('OnCancel', data, actions);
      },
      onError: err => {
        console.log('OnError', err);
        this.pagoError = err;
      },
      onClick: (data, actions) => {
        console.log('onClick', data, actions);
      },
    };
  } */

  guardarUsuario(datosUsuario: any) {
    let usuario = new Usuario(
      1, //establecimientoId
      0, //usuarioId
      datosUsuario.payer.email_address, //usuario
      '12345678', //contrasenia
      '', //claveAutorizacion
      3, //perfilId
      datosUsuario.payer.name.given_name, //nombre
      datosUsuario.payer.name.surname, //apellidoPaterno
      '', //apellidoMaterno
      datosUsuario.payer.email_address, //correoElectronico
      '1800-01-01', //fecUltCambioContrasenia
      '1800-01-01', //fecUltCambioClaveAutorizacion
      true, //activo
    );

    let usuarioPago = new UsuarioPago(
      0, //usuarioId
      0, //paypalId
      '1800-01-01', //fechaExpiracion
      '', //contraseniaIni
      datosUsuario.id, //transaccionId
      datosUsuario.intent, //intent
      datosUsuario.payer.address.country_code, //codigoPais
      datosUsuario.payer.email_address, //correo
      datosUsuario.payer.payer_id, //payerId
      datosUsuario.create_time, //fechaCreacionPayPal
      datosUsuario.create_time, //fechaModificacionPayPal
      datosUsuario.status, //estatus
      paypal.PLAN_ID, //descripcion
      500.00, //costo
      'MXN', //moneda
      1, //TipoPagoId - PayPal
      1, //TipoPlanId - Anual
      usuario.nombre + ' ' + usuario.apellidoPaterno, //usuarioNombre
      '' //contrasenia
    );

    this._authService.insertaUsuarioPago(usuario, usuarioPago).subscribe(
      result => {
        this._authService.agregarMensaje(result);
      },
      error => {
        this.pagoExito = '';
        this.pagoError = error.error;
      },
    );
  }

  descarga() {
    var link = document.createElement('a');
    link.href = url_bases.URL_ARCHIVOS + 'EFormato/PAGO 200 CONTACLUB.pdf';
    link.target = '_blank';
    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link);
  }

  abrirVideo(): void {
    let idVideo: string;
    idVideo = 'https://vimeo.com/887782279';

    let videoApp = new VideoApp();
    videoApp.id = 0;
    videoApp.titulo = 'Conoce Contaclub!';
    videoApp.descripcion = '';
    videoApp.idVideo = idVideo;
    videoApp.tipoVideo = 1;

    const dialogRef = this._dialog.open(CursoVideoDialogComponent, {
      data: videoApp,
      disableClose: true,
      width: '50%'
    });
  }
}
