<button class="user-button" mat-mini-fab [matMenuTriggerFor]="userMenu">
  <mat-icon fontSet="fa fa-fs" fontIcon="fa-twitter-square"></mat-icon>
</button>
<mat-menu #userMenu="matMenu" xPosition="before">

    <div>
        <a class="twitter-timeline" href="https://twitter.com/TwitterDev" data-chrome="nofooter noborders">
      Tweets by @TwitterDev
    </a>
    </div>

</mat-menu>