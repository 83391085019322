import { Component, EventEmitter, OnInit, Output } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
//App
import { CryptoService } from '../../../../shared/utilities/services/crypto.services';

@Component({
  selector: 'app-sign-form',
  templateUrl: './sign-form.component.html',
  styleUrls: ['./sign-form.component.scss']
})
export class SignFormComponent implements OnInit {
  @Output() sendSignForm = new EventEmitter<any>();  

  public form: FormGroup;  
  //public usuarioNuevo = {nombre: '', correo: '', contrasenia: '', tipoPago: 1};  
  constructor(private _cryptoService: CryptoService) { }

  public ngOnInit(): void {
    /* this.form = new FormGroup({
      nombre: new FormControl('', [Validators.required]),
      correo: new FormControl('', [Validators.required, Validators.email]),
      contrasenia: new FormControl('', [Validators.required]),
      tipoPago: new FormControl(1, [Validators.required])
    }); */
  }
  
  /* public transforma() {
    let objForm = this.form.getRawValue();    
    
    this.usuarioNuevo.nombre = objForm.nombre;
    this.usuarioNuevo.correo = objForm.correo;
    this.usuarioNuevo.contrasenia = this._cryptoService.crypData(objForm.contrasenia);
    this.usuarioNuevo.tipoPago = (isNaN(parseInt(objForm.tipoPago))) ? 1 : parseInt(objForm.tipoPago);
  } */

  public sign(evt: any): void {
    //console.log(evt);
    this.sendSignForm.emit(evt.value);
    /* if (this.form.valid) {
      this.transforma();
      this.sendSignForm.emit(this.usuarioNuevo);
    } */
  }
}
