import { Component } from '@angular/core';
import { MENU_ADMIN, MENU_USER } from '../../consts';
import { LocalStorageService } from '../utilities/services';
import { Usuario } from '../../pages/base/catalogs/administration/usuarios/models/usuario';

@Component({
  selector: 'app-sidebar',
  templateUrl: './sidebar.component.html',
  styleUrls: ['./sidebar.component.scss']
})
export class SidebarComponent {
  /* public routes: typeof routes = routes; */
  public isOpenUiElements = false;
  public appitems = [];
  public config = {
    paddingAtStart: true,
    interfaceWithRoute: true,
    //classname: 'my-custom-class',
    //listBackgroundColor: `rgb(208, 241, 239)`,
    //fontColor: `rgba(110, 110, 110, 0.6)`,
    fontColor: `rgba(255, 255, 255)`,
    //backgroundColor: `rgb(208, 241, 239)`,
    //selectedListFontColor: `rgba(0, 159, 227, 1)`,
    selectedListFontColor: `rgba(0,191,255)`,
    highlightOnSelect: true,
    collapseOnSelect: true,
    useDividers: false,
    //rtlLayout: false    
  };

  constructor(private _localStorageService: LocalStorageService) {
    let usuario: Usuario = this._localStorageService.obtenerUsuarioSesion();
    
    if (usuario.perfilId === 1 || usuario.perfilId === 2) { //admin
      this.appitems = MENU_ADMIN;
    } else {
      this.appitems = MENU_USER;
    }
  }
  
  public openUiElements() {
    this.isOpenUiElements = !this.isOpenUiElements;
  }
 
}
