export enum paypal {
  //DESARROLLO
  //ID = 'AVRpgp8YzqVe0J2Nh42V2xG1Wz78-e_lcf5VHeG9mEzZGQpeuHqEu6EIaue9S3QqnkpcDo7KmSMO6ZMT', //ID APP PAYPAL DEVELOPER FAGH
  //PLAN_ID = 'P-9HM32398CA379663JMBYIEMI', //PLAN ID DE PAYPAL BILLINGS FAGH

  //DESARROLLO CONTACLUB
  //ID = 'AdZIxt_6KS0FvTpEizB7qbvJQfwzYAw8Pz3M9DaHoHT0BDD2N_xNGUZkgGDfsxGik9eTY2Yqznu2P7Tw', //ID APP PAYPAL DEVELOPER SANDBOX
  //PLAN_ID = 'P-95G70821TF261414PMDFD7XY', //PLAN ID DE PAYPAL BILLINGS SANDBOX
  
  //PRODUCCION CONTACLUB
  ID = 'AeaLqfWS6yzlPJ2j4EeXfKSv6fFhC5xlpH-tzTB4boc1jUAvqEcgDuIVIZot6wjuBihgtcUmomM8NbVz', //ID APP PAYPAL PRODUCCION
  PLAN_ID = 'P-12V78148A3035384MMDNIQLI', //PLAN ID 200/500 DE PAYPAL PRODUCCION  
  //PLAN_ID = 'P-55G82094FS359691MMD6KBWY', //PLAN ID 150 DE PAYPAL PRODUCCION
  
  //NO BORRAR USUARIOS
  USUARIO_PAGO = 'zh7vN3xqEQLe/eVMkECJWeGNBRiz7ZNxXeaQ8conM7A=', //suscriptor@jyrcontadores.com
  USUARIO_CLAVE = 'bmkroWPLi4g=',  
}