import {PreloadAllModules, RouterModule, Routes} from '@angular/router';
import { NgModule } from '@angular/core';
//import { DashboardPageComponent } from './pages/dashboard/containers';
import { PresentationPageComponent } from "./pages/business/presentation/containers";
import { NotFoundComponent } from './pages/not-found/not-found.component';
import { AuthGuard } from './pages/auth/guards';

const routes: Routes = [
  {
    path: 'inicio',
    pathMatch: 'full',
    canActivate: [AuthGuard],
    component: PresentationPageComponent
  },
  //BASE
  {
    path: 'usuarios',
    canActivate: [AuthGuard],
    loadChildren: () => import('./pages/base/catalogs/administration/usuarios/usuario.module').then(m => m.UsuarioModule)
  },
  //BUSINESS
  //Catalogs
  {
    path: 'cat-cursos',
    canActivate: [AuthGuard],
    loadChildren: () => import('./pages/business/catalogs/cursos/curso.module').then(m => m.CursoModule)
  },
  {
    path: 'cat-tipo-cursos',
    canActivate: [AuthGuard],
    loadChildren: () => import('./pages/business/catalogs/tipo-cursos/tipo-curso.module').then(m => m.TipoCursoModule)
  },
  {
    path: 'cat-calculadoras',
    canActivate: [AuthGuard],
    loadChildren: () => import('./pages/business/catalogs/calculadoras/calculadora.module').then(m => m.CalculadoraModule)
  },
  {
    path: 'cat-leyes',
    canActivate: [AuthGuard],
    loadChildren: () => import('./pages/business/catalogs/leyes/ley.module').then(m => m.LeyModule)
  },
  {
    path: 'cat-libros',
    canActivate: [AuthGuard],
    loadChildren: () => import('./pages/business/catalogs/libros/libro.module').then(m => m.LibroModule)
  },
  {
    path: 'cat-revistas',
    canActivate: [AuthGuard],
    loadChildren: () => import('./pages/business/catalogs/revistas/revista.module').then(m => m.RevistaModule)
  },
  {
    path: 'cat-software',
    canActivate: [AuthGuard],
    loadChildren: () => import('./pages/business/catalogs/software/software.module').then(m => m.SoftwareModule)
  },
  {
    path: 'cat-contratos',
    canActivate: [AuthGuard],
    loadChildren: () => import('./pages/business/catalogs/contratos/contrato.module').then(m => m.ContratoModule)
  },
  {
    path: 'cat-alertas',
    canActivate: [AuthGuard],
    loadChildren: () => import('./pages/business/catalogs/alertas/alerta.module').then(m => m.AlertaModule)
  },
  {
    path: 'soporte',
    canActivate: [AuthGuard],
    loadChildren: () => import('./pages/business/catalogs/soporte/soporte.module').then(m => m.SoporteModule)
  },
  {
    path: 'sitio-contabilidad',
    canActivate: [AuthGuard],
    loadChildren: () => import('./pages/business/catalogs/vs-contabilidad/vs-contabilidad.module').then(m => m.VsContabilidadModule)
  },
  {
    path: 'sitio-facturacion',
    canActivate: [AuthGuard],
    loadChildren: () => import('./pages/business/catalogs/vs-facturacion/vs-facturacion.module').then(m => m.VsFacturacionModule)
  },
  {
    path: 'sat',
    canActivate: [AuthGuard],
    loadChildren: () => import('./pages/business/sat/descarga-masiva/descarga-masiva.module').then(m => m.DescargaMasivaModule)
  },
  /*
  {
    path: 'calculadoras',
    pathMatch: 'full',
    canActivate: [AuthGuard],
    loadChildren: () => import('./pages/business/calculadoras/calculadoras.module').then(m => m.CalculadorasModule)
  },
  {
    path: 'cursos',
    pathMatch: 'full',
    canActivate: [AuthGuard],
    loadChildren: () => import('./pages/business/cursos/cursos.module').then(m => m.CursosModule)
  },
  {
    path: 'libros',
    pathMatch: 'full',
    canActivate: [AuthGuard],
    loadChildren: () => import('./pages/business/libros/libros.module').then(m => m.LibrosModule)
  },
  {
    path: 'revistas',
    pathMatch: 'full',
    canActivate: [AuthGuard],
    loadChildren: () => import('./pages/business/revistas/revistas.module').then(m => m.RevistasModule)
  },
  {
    path: 'software',
    pathMatch: 'full',
    canActivate: [AuthGuard],
    loadChildren: () => import('./pages/business/software/software.module').then(m => m.SoftwareModule)
  },
  {
    path: 'notification',
    pathMatch: 'full',
    canActivate: [AuthGuard],
    loadChildren: () => import('./pages/notification/notification.module').then(m => m.NotificationModule)
  },*/
  {
    path: '404',
    component: NotFoundComponent
  },
  {
    path: 'login',
    loadChildren: () => import('./pages/auth/auth.module').then(m => m.AuthModule)
  },
  {
    path: '**',
    redirectTo: '404'
  }
];

@NgModule({
  imports: [
    RouterModule.forRoot(routes, {
      useHash: true,
      preloadingStrategy: PreloadAllModules
    })
  ],
  exports: [RouterModule]
})

export class AppRoutingModule {
}
