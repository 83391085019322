import { Injectable } from '@angular/core';
import { NgxSpinnerService } from 'ngx-spinner';

@Injectable({
  providedIn: 'root'
})
export class SpinneService {

  constructor(private spinneService: NgxSpinnerService) {}
  
  public iniciarSpinner() {
    this.spinneService.show();
  }

  public detenerSpinner() {
    this.spinneService.hide();
  }
}
