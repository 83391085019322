import { CollectionViewer, DataSource } from "@angular/cdk/collections";
//Rxjs
import { Observable, BehaviorSubject, of } from "rxjs";
import { catchError, finalize } from "rxjs/operators";
//App
import { Alerta, AlertaFiltro } from "../models";
import { AlertaService } from "./alerta.service";

export class AlertaDataSourceService implements DataSource<Alerta> {
    private lessonsSubject = new BehaviorSubject<Alerta[]>([]);
    private loadingSubject = new BehaviorSubject<boolean>(false);
    public loading$ = this.loadingSubject.asObservable();

    constructor() { }

    connect(collectionViewer: CollectionViewer): Observable<Alerta[]> {
        return this.lessonsSubject.asObservable();
    }

    disconnect(collectionViewer: CollectionViewer): void {
        this.lessonsSubject.complete();
        this.loadingSubject.complete();
    }

    cargaAlertas(filtro: AlertaFiltro, service: AlertaService) {
        this.loadingSubject.next(true);

        service.alertasPag(filtro)
            .pipe(
                catchError(() => of([])),
                finalize(() => this.loadingSubject.next(false))
            )
            .subscribe(data => {
                this.actualizaDatos(filtro, data);
            });
    }

    actualizaDatos(filtro: AlertaFiltro, pagina: any) {
        this.lessonsSubject.next(pagina['pagina'])
        filtro.datPag = pagina['datPag'];
    }
}