<button class="user-button" mat-mini-fab [matMenuTriggerFor]="userMenu">
  <mat-icon class="user-button__icon">person</mat-icon>
</button>
<mat-menu #userMenu="matMenu" xPosition="before">

    <div class="user-menu-title">
        <h4 class="user-menu-title__name">{{ usuarioSesion.nombre }} {{ usuarioSesion.apellidoPaterno }}</h4>
        <p class="user-menu-title__link">{{ usuarioSesion.perfilNombre }}</p>
    </div>

    <button mat-menu-item class="user-menu__item-title" (click)="datosUsuario()">
      <mat-icon class="user-menu-icon">person</mat-icon>Mis datos
    </button>

    <div class="sign-button-wrapper">
        <button class="sign-button" mat-flat-button (click)="terminarSesionEmit()">Salir</button>
    </div>

</mat-menu>