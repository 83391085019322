import { Component } from '@angular/core';
//Material
import { MatDialog } from '@angular/material/dialog';
//App
import { VideoApp } from '../../../../../shared/utilities/models';
import { CursoVideoDialogComponent } from '../../../../business/catalogs/cursos/components';

@Component({
  selector: 'app-presentation-page',
  templateUrl: './presentation-page.component.html',
  styleUrls: ['./presentation-page.component.scss']
})
export class PresentationPageComponent {
  public videoApp = new VideoApp();
  
  constructor(public dialog: MatDialog, public _dialog: MatDialog) { 
    this.videoApp.id = 1;
    this.videoApp.titulo = `*ATENCIÓN: Antes de empezar mira este breve vídeo de presentación en el que te 
      recomendamos los pasos que debes seguir antes de comenzar a utilizar tu sesión.
      Si aún así tienes alguna duda, ponte en contacto con nosotros desde el apartado de soporte.`;
    this.videoApp.descripcion = '';
    this.videoApp.idVideo = 'https://vimeo.com/566725882';
    //this.videoApp.idVideo = 'https://media.vimejs.com/720p.mp4';
    this.videoApp.tipoVideo = 1;

    this.abrirVideo();
  }

  abrirVideo(): void {
    let idVideo;
    idVideo = 'https://vimeo.com/794384915';
    
    let videoApp = new VideoApp();
    videoApp.id = 0;
    videoApp.titulo = 'Recomendaciones Contaclub';
    videoApp.descripcion = '';
    videoApp.idVideo = idVideo;
    videoApp.tipoVideo = 1;
    
    const dialogRef = this._dialog.open(CursoVideoDialogComponent, {
      data: videoApp,
      disableClose: true,
      width: '50%'
    });
  }
}
