<mat-card class="video-wrapper">
    <mat-card-title class="video-wrapper__header">
        <p>
            {{ paramVideoApp.titulo }}
        </p>
    </mat-card-title>
    <mat-card-content class="video__content">
        <div #videoAppDiv>

            <vm-player>
                <vm-video cross-origin>
                    <source data-src="{{ paramVideoApp.idVideo}} " type="video/mp4 " />
                </vm-video>
                <vm-default-ui></vm-default-ui>
            </vm-player>

            <!-- <video controls autoplay preload>
            <source src="/sample-video.webm " type="video/webm ">
                <source src="/sample-video.ogv " type="video/ogg ">
            <source [src]="paramVideoApp.idVideo " type="video/mp4 ">
            <track src="/subtitles-en.vtt " kind="subtitles " srclang="en " 
                label="English " default>
                <track src="/subtitles-fr.vtt " kind="subtitles " srclang="es "
                label="Spanish ">
            </video> -->
        </div>
    </mat-card-content>
</mat-card>