import { DatosAuditoria } from '../../../../../shared/utilities/models';

export class Alerta extends DatosAuditoria {
    constructor (
        public alertaId: number = 0,
        public titulo: string = '',
        public descripcion: string = '',
        public linkAlerta: string = '',
        public esParaTodos: boolean = true,
        public fechaInicio: string = '1800-01-01',
        public fechaFin: string = '1800-01-01',
        public leido: boolean = false,
    ) {
        super();
    }
}