<div class="not-found-page">
    <div class="not-found-page__content">
        <div class="not-found-page__title">
            <!-- <img class="not-found-page__title-img" src="./assets/hot-found/logo.svg" alt="logo"> -->
            <img class="not-found-page__title-img" src="" alt="logo">
            <h3 class="not-found-page__title-text">Contador plus</h3>
        </div>

        <mat-card class="not-found-page__card">
            <mat-card-content class="not-found-page__content">
                <h1 class="not-found-page__card-title">404</h1>

                <p class="not-found-page__card-sub-title">¡Ups! Parece que la página que estás buscando ya no existe</p>
                <p class="not-found-page__card-sub-title-second">Pero estamos aquí para traerte de vuelta a la seguridad</p>

                <button class="not-found-page__card-button" mat-raised-button color="primary" [routerLink]="routes.INICIO">Regresar a inicio</button>

            </mat-card-content>
        </mat-card>
    </div>
</div>