import { ChangeDetectionStrategy, Component, HostListener, Inject } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from "@angular/material/dialog";

@Component({
  changeDetection: ChangeDetectionStrategy.OnPush,
  selector: 'app-mensaje-confirmacion',
  templateUrl: './mensaje-confirmacion.component.html',
  styleUrls: ['./mensaje-confirmacion.component.scss']
})
export class MensajeConfirmacionComponent {
  constructor(
    @Inject(MAT_DIALOG_DATA) 
    public data: {
      titulo: string,
      mensaje: string,
      btnCancelaTexto: string,
      btnConfirmaTexto: string
    },
    private mdDialogRef: MatDialogRef<MensajeConfirmacionComponent>
  ) { }
  
  public cancelar() {
    this.cerrar(false);
  }

  public cerrar(value) {
    this.mdDialogRef.close(value);
  }

  public confirmar() {
    this.cerrar(true);
  }

  @HostListener("keydown.esc")
  public onEsc() {
    this.cerrar(false);
  }
}
