import { AfterViewInit, ChangeDetectorRef, Component, ElementRef, OnInit, OnDestroy, ViewChild, Input } from '@angular/core';
import { VideoApp } from '../../models/video-app';
import videojs from 'video.js';

@Component({
  selector: 'app-mat-video',
  templateUrl: './mat-video.component.html',
  styleUrls: ['./mat-video.component.scss']
})
export class MatVideoComponent implements AfterViewInit, OnInit, OnDestroy {
  @Input() paramVideoApp: VideoApp;
  @ViewChild('videoAppDiv') videoAppDiv: ElementRef<HTMLDivElement>;
  
  public heightVideo: number;
  public widthVideo: number;  
  public player: videojs.Player;
  
  constructor(private _changeDetectorRef: ChangeDetectorRef) { }

  ngOnInit() {
    let options = {
      //fluid: true,
      //aspectRatio: string,
      //autoplay: true,
      sources: [{
          src: this.paramVideoApp.idVideo,
          type: 'video/mp4',
      }],
    };
    this.player = videojs('elemVideo', options, function onPlayerReady() {
      console.log('onPlayerReady', this);
    });
  }

  ngAfterViewInit(): void {
    this.onResize();
    window.addEventListener('resize', this.onResize);
  }

  onResize = (): void => {
    // Amplia automáticamente el video para que se ajuste a la página hasta 1200px x 720px
    this.widthVideo = Math.min(this.videoAppDiv.nativeElement.clientWidth, 1200);
    this.heightVideo = this.widthVideo * 0.5;
    this._changeDetectorRef.detectChanges();
  }

  ngOnDestroy(): void {
    if (this.player) {
      this.player.dispose();
    }

    window.removeEventListener('resize', this.onResize);
  }
}
