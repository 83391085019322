<app-layout>

    <mat-toolbar class="page-header" role="heading">
        <h1>Bienvenido(a) Contador(a)</h1>
    </mat-toolbar>

    <div class="presentation-wrapper">
        <app-video-app [paramVideoApp]='videoApp'></app-video-app>
        <!-- <app-vime-app [paramVideoApp]='videoApp'></app-vime-app> -->
        <!-- <app-mat-video [paramVideoApp]='videoApp'></app-mat-video> -->
    </div>

    <app-footer></app-footer>

</app-layout>