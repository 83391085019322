import { Injectable } from '@angular/core';
import * as CryptoJS from 'crypto-js';

@Injectable()
export class CryptoService {
  constructor() { }

  crypData(texto: string) {
    //Llave
    let llaveGen = "MiLlaveX";    
    llaveGen = CryptoJS.enc.Utf8.parse(llaveGen);
    llaveGen = CryptoJS.MD5(llaveGen)    
    //Opciones encriptado
    var options = { 
      mode: CryptoJS.mode.ECB,
      padding: CryptoJS.pad.Pkcs7
    };
    
    let textWordArray = CryptoJS.enc.Utf8.parse(texto);
    let encrypted = CryptoJS.TripleDES.encrypt(textWordArray, llaveGen, options);
    let base64String = encrypted.toString()
    
    return base64String;
  }
}
