export enum routes {
  //Rutas
  CONSULTA = 'cat-cursos/listado',
  CAPTURA = 'cat-cursos/captura/',
  //Acciones
  URL_BASE = '/PriCursos/TipoCurso/',
  URL_DESCARGA = '/PriUtilerias/Archivos/DescargaArchivoBd/ECurso/',
  URL_CARGA = '/PriUtilerias/Archivos/SubeArchivoBd/ECurso/',
  URL_DESCARGA_VIDEO = '/PriUtilerias/Archivos/DescargaArchivoBd/ECursoVideo/',
  URL_CONSULTA_PAGINADA = 'CursoPag',
  URL_CONSULTA_X_ID = 'CursoXId',
  URL_COMBO = 'CursoCmb',
  URL_INSERTA = 'CursoInserta',
  URL_ACTUALIZA = 'CursoActualiza',
  URL_ELIMINA = 'CursoElimina',  
}
