<mat-card *ngIf="curso.cursoId" class="card-design">

    <mat-card-title class="card-design__header" matTooltip="{{curso.titulo}}" matTooltipClass="color-tooltip">
        <h5 *ngIf="curso.titulo.length >= 20" class="card-design__title">{{ curso.titulo | slice:0:18 }}..</h5>
        <h5 *ngIf="curso.titulo.length < 20" class="card-design__title">{{ curso.titulo }}</h5>
        <mat-icon>video_library</mat-icon>
    </mat-card-title>

    <mat-card-content class="card-design__content">
        <div class="card-design__content-info">
            <h6 class="card-design__content-info-title">
                {{ curso.descripcion }}
            </h6>
        </div>

        <div class="card-design__content-stats">
            <button *ngIf="curso.linkCurso != ''" class="card-design__content-stats-btn" matTooltip="Ver video" matTooltipPosition="before" mat-flat-button color="warn" (click)="abrirVideo()">
                <mat-icon>play_circle_filled</mat-icon>
            </button>

            <button *ngIf="curso.linkMaterialCurso != ''" mat-flat-button color="warn" matTooltip="Descargar material" matTooltipPosition="after" (click)="descarga()">
                <mat-icon>download_for_offline</mat-icon>
            </button>
        </div>
    </mat-card-content>

</mat-card>