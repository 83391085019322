import { Component, Inject } from '@angular/core';
//Material
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
//App
import { VideoApp } from '../../../../../../shared/utilities/models/video-app';

@Component({
  selector: 'app-curso-video-dialog',
  templateUrl: './curso-video-dialog.component.html',
  styleUrls: ['./curso-video-dialog.component.scss']
})

export class CursoVideoDialogComponent {

  constructor(
    public _dialogRef: MatDialogRef<CursoVideoDialogComponent>,
    @Inject(MAT_DIALOG_DATA) public videoApp: VideoApp
  ) { }

  cerrar() {
    this._dialogRef.close();
  }
}
