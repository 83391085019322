import { NgModule, CUSTOM_ELEMENTS_SCHEMA, NO_ERRORS_SCHEMA } from '@angular/core';
import { RouterModule } from '@angular/router';
import { CommonModule } from '@angular/common';
import { FormsModule } from '@angular/forms';
import { YouTubePlayerModule } from "@angular/youtube-player";
//npm
import { NgMaterialMultilevelMenuModule, MultilevelMenuService } from 'ng-material-multilevel-menu';
import { NgxSpinnerModule } from 'ngx-spinner';
//Material
import { MatListModule } from '@angular/material/list';
import { MatIconModule } from '@angular/material/icon';
import { MatButtonModule } from '@angular/material/button';
import { MatMenuModule } from '@angular/material/menu';
import { MatSelectModule } from '@angular/material/select';
import { MatSidenavModule } from '@angular/material/sidenav';
import { MatCardModule } from '@angular/material/card';
import { MatInputModule } from '@angular/material/input';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatToolbarModule } from '@angular/material/toolbar';
import { MatDatepickerModule } from '@angular/material/datepicker';
import { MatMomentDateModule, MAT_MOMENT_DATE_ADAPTER_OPTIONS } from '@angular/material-moment-adapter';
import { MAT_MOMENT_DATE_FORMATS, MomentDateAdapter } from '@angular/material-moment-adapter';
import { DateAdapter, MAT_DATE_FORMATS, MAT_DATE_LOCALE} from '@angular/material/core';
// Videos
import { VimeModule } from '@vime/angular';
//App components
import { HeaderModule } from './header/header.module';
import { SidebarComponent } from './sidebar/sidebar.component';
import { FooterComponent } from './footer/footer.component';
import { LayoutComponent } from './layout/layout.component';
import { 
  VideoAppComponent,
  VimeAppComponent,
  MatVideoComponent,
  MensajeComponent,
  MensajeConfirmacionComponent,
  CargaArchivoComponent
} from './utilities/components';
//App services
import { FuncionesTextoService } from './utilities/services'
//App pipes
import { VimeoUrlPipe, GoogleDriveUrlPipe } from './utilities/pipes'
//App directives
import {
  DeshabilitaControlDirective,
  VisibleControlDirective
} from './utilities/directives'

@NgModule({
  declarations: [
    SidebarComponent,
    FooterComponent,
    LayoutComponent,
    VideoAppComponent,
    VimeAppComponent,
    MatVideoComponent,
    MensajeComponent,
    MensajeConfirmacionComponent,
    CargaArchivoComponent,
    VimeoUrlPipe,
    GoogleDriveUrlPipe,
    DeshabilitaControlDirective,
    VisibleControlDirective
  ],
  imports: [    
    CommonModule,
    FormsModule,
    RouterModule,
    MatListModule,
    MatIconModule,
    MatButtonModule,
    MatMenuModule,
    MatSelectModule,
    MatSidenavModule,
    MatCardModule,
    MatInputModule,
    MatFormFieldModule,
    MatToolbarModule,
    MatDatepickerModule,
    MatMomentDateModule,
    YouTubePlayerModule,
    NgMaterialMultilevelMenuModule,
    NgxSpinnerModule,
    HeaderModule,
    VimeModule
  ],
  exports: [
    HeaderModule,
    SidebarComponent,
    FooterComponent,
    LayoutComponent,
    VideoAppComponent,
    VimeAppComponent,
    MatVideoComponent,
    MensajeComponent,
    MensajeConfirmacionComponent,
    CargaArchivoComponent,
    DeshabilitaControlDirective,
    VisibleControlDirective,
    NgxSpinnerModule
  ],
  schemas: [
    CUSTOM_ELEMENTS_SCHEMA,
    NO_ERRORS_SCHEMA
  ],
  providers: [
    MultilevelMenuService,
    FuncionesTextoService,
    {provide: MAT_DATE_LOCALE, useValue: 'es'},
    {provide: DateAdapter, useClass: MomentDateAdapter, deps: [MAT_DATE_LOCALE, MAT_MOMENT_DATE_ADAPTER_OPTIONS]},
    {provide: MAT_DATE_FORMATS, useValue: MAT_MOMENT_DATE_FORMATS},
  ],
})
export class SharedModule { }
