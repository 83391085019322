import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable, of, Subject } from 'rxjs';
import { switchMap, map } from 'rxjs/operators';

import { url_bases } from "../../../consts";
import { routes, paypal } from "../consts";
import { UsuarioPago } from "../models";
import { Usuario } from '../../base/catalogs/administration/usuarios/models/usuario';
import { LocalStorageService } from '../../../shared/utilities/services/local-storage.service';
import { UsuarioCorreo } from '../../business/catalogs/soporte/models';


@Injectable({
  providedIn: 'root'
})
export class AuthService {
  private mensaje$: Subject<string> = new Subject<string>();

  constructor(
    private _httpClient: HttpClient,
    private _localStorageService: LocalStorageService
  ) {}
  
  getMensaje(): Observable<string> {
    return this.mensaje$.asObservable();
  }
  agregarMensaje(msj: string) {
    this.mensaje$.next(msj);
  }

  public autentifica(datosLogin: any): Observable<any> {
    datosLogin.urlAutentificacionAuto = 'Ok';

    return this._httpClient.post<any>(
      url_bases.URL_SISEGUI2020API + routes.URL_AUTENTIFICA,
      datosLogin
    );
  }

  public insertaUsuarioPago(usuario: Usuario, usuarioPago: UsuarioPago): Observable<any> {
    let datosLogin = { 
      usuario: paypal.USUARIO_PAGO,
      contrasenia: paypal.USUARIO_CLAVE,
      urlAutentificacionAuto: 'Ok'
    }    
    
    return this._httpClient.post<any>(
      url_bases.URL_SISEGUI2020API + routes.URL_AUTENTIFICA,
      datosLogin
    ).pipe(
      switchMap((authToken: any) => {
          if (authToken['usuarioSesion']) {
            this._localStorageService.estableceUsuarioSesion(authToken['usuarioSesion']);
            this._localStorageService.estableceTokenSesion(authToken['tokenApis']);

            return this._httpClient.post<any>(
              url_bases.URL_CLIENTEAPI + routes.URL_PAGO_INSERTA, usuarioPago
            ).pipe(
              map((result: any) => {
                this._localStorageService.terminarSesion();
                return result;
              })
            );
          } else {
            this._localStorageService.terminarSesion();
            return of();
          }
        }
      )
    );
  }
  public enviaSoporte(usuarioCorreo: UsuarioCorreo): Observable<any> {
    return this._httpClient.post<any>(
      url_bases.URL_CLIENTEAPI + routes.URL_USUARIO_SOPORTE,
      usuarioCorreo
    );
  }
  public UsuarioRecuperaContrasenia(usuarioCorreo: string): Observable<any> {
    let datosLogin = { 
      usuario: paypal.USUARIO_PAGO,
      contrasenia: paypal.USUARIO_CLAVE,
      urlAutentificacionAuto: 'Ok'
    }

    return this._httpClient.post<any>(
      url_bases.URL_SISEGUI2020API + routes.URL_AUTENTIFICA,
      datosLogin
    ).pipe(
      switchMap((authToken: any) => {
          if (authToken['usuarioSesion']) {
            this._localStorageService.estableceUsuarioSesion(authToken['usuarioSesion']);
            this._localStorageService.estableceTokenSesion(authToken['tokenApis']);

            return this._httpClient.get<any>(
              url_bases.URL_CLIENTEAPI + routes.URL_USUARIO_RECUPERA_CONTRASENIA + '/' + usuarioCorreo
            ).pipe(
              map((result: any) => {
                this._localStorageService.terminarSesion();
                return result;
              })
            );
          } else {
            this._localStorageService.terminarSesion();
            return of();
          }
        }
      )
    );
  }
}
