<form class="form" [formGroup]="form" (ngSubmit)="login()">
    <mat-form-field class="form__input">
        <input matInput placeholder="Correo" type="text" formControlName="usuario">
    </mat-form-field>

    <mat-form-field class="form__input">
        <input matInput placeholder="Contraseña" type="password" formControlName="contrasenia">
    </mat-form-field>

    <div class="form-actions">
        <button class="form-actions__login" mat-raised-button color="primary" type="submit">Entrar</button>
        <button class="form-actions__forget" mat-button color="warn" (click)="openDialog()">Olvide mi contraseña</button>
    </div>
</form>