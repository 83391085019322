import { Component, OnInit, ViewChild, OnDestroy, AfterViewInit, ElementRef } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
//rxjs
import { fromEvent } from 'rxjs';
import { debounceTime, distinctUntilChanged, tap } from 'rxjs/operators';
//Material
import { MatPaginator } from '@angular/material/paginator';
//Shared
import { FuncionesArreglosService } from '../../../../../../shared/utilities/services'
//App
import { routes } from "../../consts";
import { CursoFiltro } from '../../models';
import { CursoService, CursoDataSourceService } from '../../services';

@Component({
  selector: 'app-curso-curso',
  templateUrl: './curso-curso.component.html',
  styleUrls: ['./curso-curso.component.scss']
})

export class CursoCursoComponent implements OnInit, OnDestroy, AfterViewInit {
  public error: string;
  public cursoFiltro = new CursoFiltro();  
  public filTipoCurso = 0;
  public dataSourceService: CursoDataSourceService;

  @ViewChild(MatPaginator, { static: true }) paginator: MatPaginator;
  @ViewChild('titulo') titulo: ElementRef;
  @ViewChild('descripcion') descripcion: ElementRef;
  @ViewChild('anio') anio: ElementRef;

  constructor(
    private _route: ActivatedRoute,
    private _router: Router,
    private _cursoService: CursoService,
    private _funcionesArreglosService: FuncionesArreglosService
  ) { }

  ngOnInit(): void {
    this.dataSourceService = new CursoDataSourceService();
    let pagina: any = this._route.snapshot.data["dataSourceService"];
    this.filTipoCurso = parseInt(this._route.snapshot.paramMap.get('id'));
    
    if (parseInt(this._route.snapshot.paramMap.get('anio')) > 0) {
      this.cursoFiltro.filAnio = parseInt(this._route.snapshot.paramMap.get('anio'));
    }
    
    if (pagina['error']) {
      this.error = 'Ha ocurrio un error en el servidor, intente nuevamente.';
    } else if (pagina.pagina) {
      if (pagina.pagina.length > 0) {
        this.dataSourceService.actualizaTarjetas(this.cursoFiltro, pagina, this._funcionesArreglosService);
      } else {
        this.error = 'No existen registros a mostrar.';
      }
    } else {
      this.error = 'Ha ocurrio un error en el servidor, intente nuevamente.';
    }
  }

  ngOnDestroy(): void {
    this.dataSourceService = null;
  }

  ngAfterViewInit() {    
    /* fromEvent(this.input.nativeElement, 'keyup')
      .pipe(
        debounceTime(300),
        distinctUntilChanged(),
        tap(() => {
          this.paginator.pageIndex = 1;
          this.filtrar();
        })
      )
      .subscribe(); */

    this.paginator.page
      .pipe(
        tap((event) => {
          this.cursoFiltro.datPag.currentPage = event.pageIndex + 1;
          this.cursoFiltro.datPag.pageSize = event.pageSize;
          this.cursoFiltro.datPag.pageSizePrev = event.previousPageIndex;
          this.cursoFiltro.datPag.totalLines = event.length;
          this.filtrar();
        })
      )
      .subscribe();
  }

  cargaCursoPagina() {
    this.cursoFiltro.tipoCursoId = this.filTipoCurso;    
    this.dataSourceService.cargaTarjetas(
      this.cursoFiltro,
      this._cursoService,
      this._funcionesArreglosService
    );
  }

  filtrar() {    
    this.cursoFiltro.filTitulo = this.titulo.nativeElement.value;
    this.cursoFiltro.filDescripcion = this.descripcion.nativeElement.value;
    //this.cursoFiltro.filAnio = (this.anio.nativeElement.value === '' ? 0 : this.anio.nativeElement.value);
    this.cargaCursoPagina();
  }

  limpiaFiltrar() {
    this.paginator.pageIndex = 0;
    this.cursoFiltro.datPag.currentPage = 1;
    this.titulo.nativeElement.value = '';
    this.descripcion.nativeElement.value = '';
    //this.anio.nativeElement.value = '';
    this.cursoFiltro.filTitulo = '';
    this.cursoFiltro.filDescripcion = '';
    //this.cursoFiltro.filAnio = 0;
    this.cargaCursoPagina();
  }
}
