import { AfterViewInit, ChangeDetectorRef, Component, ElementRef, OnInit, OnDestroy, ViewChild, Input } from '@angular/core';
import { VideoApp } from '../../models/video-app';

@Component({
  selector: 'app-video-app',
  templateUrl: './video-app.component.html',
  styleUrls: ['./video-app.component.scss']
})
export class VideoAppComponent implements AfterViewInit, OnInit, OnDestroy {
  @Input() paramVideoApp: VideoApp;
  @ViewChild('videoAppDiv') videoAppDiv: ElementRef<HTMLDivElement>;
  public heightVideo: number;
  public widthVideo: number;

  constructor(private _changeDetectorRef: ChangeDetectorRef) { }

  ngOnInit() {
    // Este código carga el reproductor de la API en un iframe de manera asíncrona, siguiendo las instrucciones:
    // https://developers.google.com/youtube/iframe_api_reference#Getting_Started
    const tag = document.createElement('script');
    tag.src = "https://www.youtube.com/iframe_api";
    document.body.appendChild(tag);
  }

  ngAfterViewInit(): void {
    this.onResize();
    window.addEventListener('resize', this.onResize);
  }

  onResize = (): void => {
    // Amplia automáticamente el video para que se ajuste a la página hasta 1200px x 720px
    this.widthVideo = Math.min(this.videoAppDiv.nativeElement.clientWidth, 1200);
    this.heightVideo = this.widthVideo * 0.5;
    this._changeDetectorRef.detectChanges();
  }

  ngOnDestroy(): void {
    window.removeEventListener('resize', this.onResize);
  }
}
