import { Directive, ElementRef, Input, OnChanges } from '@angular/core';

@Directive({
  selector: '[visibleControl]'
})

export class VisibleControlDirective implements OnChanges {

  @Input() visibleControl: boolean 

  constructor(private elementRef: ElementRef) { }

  ngOnChanges() {    
    if (!this.visibleControl) {
      this.elementRef.nativeElement.style.display = "none";
    }    
  }
}