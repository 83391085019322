import { Component, EventEmitter, Inject, OnInit, Output } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { MatDialog, MatDialogRef, MAT_DIALOG_DATA} from '@angular/material/dialog';
import { AuthService } from '../../services';

@Component({
  selector: 'app-recover-pass',
  templateUrl: './recover-pass.component.html',
  styleUrls: ['./recover-pass.component.scss']
})
export class RecoverPassComponent implements OnInit {
  public form: FormGroup;
  public usuarioTmp = {correoUsuario: ''};
  public error: string = '';

  constructor(public dialogRef: MatDialogRef<RecoverPassComponent>,
              @Inject(MAT_DIALOG_DATA) public data: any,
              private _authService: AuthService,) { }

  public ngOnInit(): void {
    this.form = new FormGroup({
      correoUsuario: new FormControl('', [Validators.required])      
    });
  }

  public recover(): void {
    if (this.form.valid) {
      let objForm = this.form.getRawValue();    
      this.usuarioTmp.correoUsuario = objForm.correoUsuario;
      this.form.reset();
      this.error = 'Espere un momento....';

      this._authService.UsuarioRecuperaContrasenia(this.usuarioTmp.correoUsuario).subscribe(
        result => {
          this.error = result
        }
      );
    }
  }
}
