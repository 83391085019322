import { Component, EventEmitter, Input, Output } from '@angular/core';
import { Router } from '@angular/router';
//App
import { routes } from '../../../../consts';
import { LocalStorageService } from '../../../utilities/services';
import { Usuario } from '../../../../pages/base/catalogs/administration/usuarios/models';

@Component({
  selector: 'app-header',
  templateUrl: './header.component.html',
  styleUrls: ['./header.component.scss']
})
export class HeaderComponent {
  @Input() isMenuOpened: boolean;
  @Output() isShowSidebar = new EventEmitter<boolean>();

  public usuarioSesion: Usuario;
  public routers: typeof routes = routes;

  constructor(
    private _localStorage: LocalStorageService,
    private router: Router
  ) {
    this.usuarioSesion = this._localStorage.obtenerUsuarioSesion();
  }

  public openMenu(): void {
    this.isMenuOpened = !this.isMenuOpened;
    this.isShowSidebar.emit(this.isMenuOpened);
  }

  public terminarSesion(): void {
    this._localStorage.terminarSesion();
    this.router.navigate([this.routers.LOGIN]);
  }
}
