export class Paginador {
    constructor(
      public currentPage: number = 0,
      public pageSize: number = 10,
      public pageSizePrev: number = 0,
      public startLine: number = 0,
      public subFuncionId: string = '',
      public totalLines: number = 0
    ) {}
  }
