<mat-card *ngIf="error != ''" class="consult-wrapper">
    <mat-card-title>
        <div class="error-card">
            {{ error }}
        </div>
    </mat-card-title>
    <mat-card-content class="consult-table-wrapper__content"></mat-card-content>
</mat-card>

<mat-card class="consult-wrapper">

    <mat-card-title>
        <app-curso-filtro (eventoFiltro)="filtrar($event)"></app-curso-filtro>
    </mat-card-title>

    <mat-card-content class="consult-table-wrapper__content">

        <table class="consult-table-wrapper__table" mat-table [dataSource]="dataSourceService" matSort matSortActive="cursoId" matSortDirection="asc" matSortDisableClear>
            <!-- tipoCursoId -->
            <ng-container matColumnDef="tipoCursoId">
                <th class="consult-table-wrapper__table-header" mat-header-cell *matHeaderCellDef mat-sort-header> Tipo curso </th>
                <td class="consult-table-wrapper__table-body" mat-cell *matCellDef="let element"> {{element.tipoCursoId}} </td>
            </ng-container>
            <!-- cursoId -->
            <ng-container matColumnDef="cursoId">
                <th class="consult-table-wrapper__table-header" mat-header-cell *matHeaderCellDef mat-sort-header> Curso Id </th>
                <td class="consult-table-wrapper__table-body" mat-cell *matCellDef="let element"> {{element.cursoId}} </td>
            </ng-container>
            <!-- cursoNombre -->
            <ng-container matColumnDef="cursoNombre">
                <th class="consult-table-wrapper__table-header" mat-header-cell *matHeaderCellDef mat-sort-header> Nombre </th>
                <td class="consult-table-wrapper__table-body" mat-cell *matCellDef="let element"> {{element.cursoNombre}} </td>
            </ng-container>
            <!-- titulo -->
            <ng-container matColumnDef="titulo">
                <th class="consult-table-wrapper__table-header" mat-header-cell *matHeaderCellDef mat-sort-header> Título </th>
                <td class="consult-table-wrapper__table-body" mat-cell *matCellDef="let element"> {{element.titulo}} </td>
            </ng-container>
            <!-- descripcion -->
            <ng-container matColumnDef="descripcion">
                <th class="consult-table-wrapper__table-header" mat-header-cell *matHeaderCellDef mat-sort-header> Descripción </th>
                <td class="consult-table-wrapper__table-body" mat-cell *matCellDef="let element"> {{element.descripcion}} </td>
            </ng-container>
            <!-- NombreArchivo -->
            <ng-container matColumnDef="anio">
                <th class="consult-table-wrapper__table-header" mat-header-cell *matHeaderCellDef mat-sort-header> Año </th>
                <td class="consult-table-wrapper__table-body" mat-cell *matCellDef="let element"> {{element.anio}} </td>
            </ng-container>
            <!-- activo -->
            <ng-container matColumnDef="activo">
                <th class="consult-table-wrapper__table-header" mat-header-cell *matHeaderCellDef> Activo </th>
                <td class="consult-table-wrapper__table-body" mat-cell *matCellDef="let element">
                    <span *ngIf="element.activo">Activo</span>
                    <span *ngIf="!element.activo">Inactivo</span>
                </td>
            </ng-container>
            <!-- accion consultar -->
            <ng-container matColumnDef="consultar">
                <th class="consult-table-wrapper__table-header" mat-header-cell *matHeaderCellDef></th>
                <td mat-cell *matCellDef="let element">
                    <button mat-raised-button color="info" (click)="consultar(element)">
                        <mat-icon>visibility</mat-icon>
                    </button>
                </td>
            </ng-container>
            <!-- accion editar -->
            <ng-container matColumnDef="editar">
                <th class="consult-table-wrapper__table-header" mat-header-cell *matHeaderCellDef></th>
                <td mat-cell *matCellDef="let element">
                    <button mat-raised-button color="primary" (click)="editar(element)">
                        <mat-icon>edit</mat-icon>
                    </button>
                </td>
            </ng-container>
            <!-- accion eliminar -->
            <ng-container matColumnDef="eliminar">
                <th class="consult-table-wrapper__table-header" mat-header-cell *matHeaderCellDef></th>
                <td mat-cell *matCellDef="let element">
                    <button mat-raised-button color="warn" (click)="iniciaEliminar(element)">
                        <mat-icon>delete</mat-icon>
                    </button>
                </td>
            </ng-container>
            <!-- mostrar columnas -->
            <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
            <tr mat-row *matRowDef="let row; columns: displayedColumns;"></tr>

        </table>
        <!-- accion nuevo -->
        <div class="consult-table-wrapper__content__general-action">
            <div class="consult-table-wrapper__content__general-action__btn">
                <button mat-raised-button color="primary" (click)="agregar()">
                    Agregar
                </button>
            </div>
        </div>

    </mat-card-content>

    <div class="pagination">
        <mat-paginator [length]="cursoFiltro.datPag?.totalLines" [pageSize]="cursoFiltro.datPag?.pageSize" [pageSizeOptions]="[5, 10, 20, 40, 100]" showFirstLastButtons></mat-paginator>
    </div>

</mat-card>