export enum url_bases {
  //PRODUCCION
  URL_SISEGUI2020API = 'https://contaclub.vs-consultoria.com:9070/ApiV1',
  URL_CLIENTEAPI = 'https://contaclub.vs-consultoria.com:9071/ApiV1',
  URL_ARCHIVOS = 'https://contaclub.vs-consultoria.com/AdminArchivosJYR/',
  //DESARROLLO
  // URL_SISEGUI2020API = 'http://localhost:9043/ApiV1',
  // //URL_CLIENTEAPI = 'http://localhost:9041/ApiV1',
  // URL_CLIENTEAPI = 'https://localhost:44305/ApiV1',
  // URL_ARCHIVOS = 'http://localhost/AdminArchivosJYR/',
}
