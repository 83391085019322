<div class="search">
    <div class="search__title">
        Buscar por:
    </div>

    <mat-form-field class="search__search-input">
        <input matInput placeholder="Título" #titulo class="search-input__border">
    </mat-form-field>

    <mat-form-field class="search__search-input">
        <input matInput placeholder="Descripción" #descripcion class="search-input__border">
    </mat-form-field>

    <!--<mat-form-field class="search__search-input">
        <input matInput placeholder="Año" #anio class="search-input__border">
    </mat-form-field>-->
    <button class="search__search-button" mat-mini-fab (click)="filtrar()" matTooltip="Filtrar">
        <mat-icon>search</mat-icon>
    </button>
    <button class="search__search-button" mat-mini-fab (click)="limpiaFiltrar()" matTooltip="Limpiar">
        <mat-icon class="filter-wrapper__icon">clear</mat-icon>
    </button>
</div>

<div *ngIf="error != ''" class="error-card">
    {{ error }}
</div>

<div *ngFor="let cursos of dataSourceService.cursos$ | async">
    <div class="card-wrapper">
        <div *ngFor="let curso of cursos" class="content-card-wrapper">
            <app-curso-video-card [curso]='curso'></app-curso-video-card>
        </div>
    </div>
</div>

<div class="pagination">
    <mat-paginator [length]="cursoFiltro.datPag?.totalLines" [pageSize]="cursoFiltro.datPag?.pageSize" [pageSizeOptions]="[4, 8, 12, 24, 48]" showFirstLastButtons></mat-paginator>
</div>