import { Component, OnInit, EventEmitter, Output } from '@angular/core';
import { FormBuilder, FormGroup } from '@angular/forms';
import { CursoFiltro } from '../../models';
//App
import { TipoVideoCurs } from '../../models/enumeraciones';

@Component({
  selector: 'app-curso-filtro',
  templateUrl: './curso-filtro.component.html',
  styleUrls: ['./curso-filtro.component.scss']
})
export class CursoFiltroComponent implements OnInit {
  @Output() eventoFiltro: EventEmitter<any> = new EventEmitter<any>();

  public muestraFiltros = false;
  public filtroFormGroup: FormGroup;
  public tiposVideo: any[] = [];
  public anios = [2018, 2019, 2020, 2021, 2022, 2023, 2024, 2025, 2026, 2027, 2028, 2029, 2030];

  constructor(private formBuilder: FormBuilder) {}

  public ngOnInit(): void {
    for (const item in TipoVideoCurs) {
      if (isNaN(Number(item))) {
        this.tiposVideo.push({ text: item, value: TipoVideoCurs[item] });
      }
    }

    this.creaFiltroFormGroup();
  }

  private creaFiltroFormGroup() {
    this.filtroFormGroup = this.formBuilder.group({
      tipoCursoId: ['0'],
      filCursoId: [''],
      filCursoNombre: [''],
      filTitulo: [''],
      filDescripcion: [''],
      filTipoVideo: [TipoVideoCurs.Ninguno],
      filAnio: ['0'],
      filtraFilActivo: [false],
      filActivo: [false],
    });
  }

  public muestraFormFiltros(): void {
    this.muestraFiltros = !this.muestraFiltros;
  }

  public limpiaFormFiltros(form: any): void {
    form.reset();
    this.filtrar(new CursoFiltro());
  }

  public obtenerFiltro(values: any) {
    let cursoFiltro = new CursoFiltro()
    cursoFiltro.tipoCursoId = (values.tipoCursoId !== '0' && values.tipoCursoId !== '' && !isNaN(values.tipoCursoId) && values.tipoCursoId !== null) ? parseInt(values.filCalculadoraId) : 0;
    cursoFiltro.filCursoId = (values.filCursoId !== '0' && values.filCursoId !== '' && !isNaN(values.filCursoId) && values.filCursoId !== null) ? parseInt(values.filCursoId) : 0;
    //cursoFiltro.filCursoNombre = (values.filCursoNombre !== '' && values.filCursoNombre !== null) ? values.filCursoNombre : '';
    cursoFiltro.filTitulo = (values.filTitulo !== '' && values.filTitulo !== null) ? values.filTitulo : '';
    cursoFiltro.filDescripcion = (values.filDescripcion !== '' && values.filDescripcion !== null) ? values.filDescripcion : '';
    cursoFiltro.filAnio = (values.filAnio !== '0' && values.filAnio !== '' && !isNaN(values.filAnio) && values.filAnio !== null) ? parseInt(values.filAnio) : 0;
    cursoFiltro.filTipoVideo = (values.filTipoVideo !== null) ? values.filTipoVideo : 0;
    cursoFiltro.filActivo = (values.filActivo !== null) ? values.filActivo : false;
    if (cursoFiltro.filActivo) {
      cursoFiltro.filtraFilActivo = true;
    } else {
      cursoFiltro.filtraFilActivo = false;
    }

    this.filtrar(cursoFiltro);
  }

  public filtrar(cursoFiltro: CursoFiltro): void {
    this.eventoFiltro.emit(cursoFiltro);
  }
}
