import { Component, OnInit } from '@angular/core';
//App
import { AlertaService } from '../../../../pages/business/catalogs/alertas/services';
import { AlertaFiltro, Alerta, AlertaUsuario } from '../../../../pages/business/catalogs/alertas/models';
import { MensajeService } from '../../../utilities/components/mensaje/mensaje.service'
import { Usuario } from '../../../../pages/base/catalogs/administration/usuarios/models/usuario';
import { LocalStorageService } from '../../../utilities/services/local-storage.service';

@Component({
  selector: 'app-notifications',
  templateUrl: './notifications.component.html',
  styleUrls: ['./notifications.component.scss']
})
export class NotificationsComponent implements OnInit {
  public alertas: Alerta[];  
  public numAlertas = 0;
  public usuarioSession: Usuario;

  constructor(
    private _alertaService: AlertaService,
    private _mensajeService: MensajeService,
    private _localStorageService: LocalStorageService
  ) {}

  ngOnInit() {
    this.usuarioSession = this._localStorageService.obtenerUsuarioSesion();
    this.obtenerAlertasHoy();    
  }

  obtenerAlertasHoy() {
    let filtro = new AlertaFiltro();
    let fechaDia = new Date();
    let formato = fechaDia.getFullYear().toString() + '-';
    
    formato += (fechaDia.getMonth() + 1) < 10 ? 
      '0' + (fechaDia.getMonth() + 1).toString() : 
      (fechaDia.getMonth() + 1).toString();
    formato += '-';
    formato += (fechaDia.getDate()) < 10 ? 
      '0' + (fechaDia.getDate()).toString() : 
      (fechaDia.getDate()).toString();
    
    filtro.filFechaInicio = formato;
    filtro.filFechaFin = formato;

    this._alertaService.alertasXUsuarioId(this.usuarioSession.usuarioId).subscribe(
      result => {
        if (result.length > 0) {
          this.numAlertas = 0;
          this.alertas = result;
          for (let i = 0; i < this.alertas.length; i++) {
            if (!this.alertas[i].leido)
              this.numAlertas++;
          }          
        }
      }
    );
  }

  mostrarAlerta(alerta: any) {
    if (!alerta.leido) {
      let alertaUsuario = new AlertaUsuario(
        alerta.alertaId,
        this.usuarioSession.usuarioId,
        true,
      );
  
      this._alertaService.alertaUsuarioInserta(alertaUsuario).subscribe(
        result => {
          this.obtenerAlertasHoy();
        }
      );
    }

    let opciones = {
      titulo: alerta.titulo,
      mensaje: alerta.descripcion,
      btnAceptar: 'Cerrar'
    };

    this._mensajeService.open(opciones);
  }
}
