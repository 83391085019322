import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormsModule } from '@angular/forms';
//Material
import { MatToolbarModule } from '@angular/material/toolbar';
import { MatDialogModule } from '@angular/material/dialog';
import { MatButtonModule } from '@angular/material/button';
//App
import { PresentationPageComponent } from './containers';
import { SharedModule } from '../../../shared/shared.module';

@NgModule({
  declarations: [
    PresentationPageComponent,
  ],
  imports: [
    CommonModule,
    MatToolbarModule,
    MatDialogModule,
    MatButtonModule,
    FormsModule,
    SharedModule
  ],
  exports: [],
  providers: []
})
export class PresentationModule { }
