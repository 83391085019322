import { routes } from './routes';

export const MENU_USER = [
  {
    label: 'Inicio',
    icon: 'home',
    link: routes.INICIO,
  },
  {
    label: 'Cursos',
    icon: 'school',
    items: [
      {
        label: 'Contabilidad',
        icon: 'videocam',
        //link: routes.VIDEOCURSO + '/1'
        items: [
          {
            label: '2021',
            icon: 'today',
            link: routes.VIDEOCURSO + '/1/2021'
          },
          {
            label: '2022',
            icon: 'today',
            link: routes.VIDEOCURSO + '/1/2022'
          },
          {
            label: '2023',
            icon: 'today',
            link: routes.VIDEOCURSO + '/1/2023'
          },
          {
            label: '2024',
            icon: 'today',
            link: routes.VIDEOCURSO + '/1/2024'
          }
        ]
      },
      {
        label: 'Talleres-Contables',
        icon: 'videocam',
        //link: routes.VIDEOCURSO + '/19',
        items: [
          {
            label: '2022',
            icon: 'today',
            link: routes.VIDEOCURSO + '/19/2022'
          },
          {
            label: '2023',
            icon: 'today',
            link: routes.VIDEOCURSO + '/19/2023'
          },
          {
            label: '2024',
            icon: 'today',
            link: routes.VIDEOCURSO + '/19/2024'
          }
        ]
      },
      // {
      //   label: 'CONTPAQi',
      //   icon: 'videocam',
      //   items: [
      //     {
      //       label: 'Bancos',
      //       icon: 'assignment',
      //       link: routes.VIDEOCURSO + '/2/0'
      //     },
      //     {
      //       label: 'Contabilidad Cierre y saldos iniciales',
      //       icon: 'assignment',
      //       link: routes.VIDEOCURSO + '/3/0'
      //     },
      //     {
      //       label: 'Taller Ajuste al subsidio e ISR',
      //       icon: 'assignment',
      //       link: routes.VIDEOCURSO + '/4/0'
      //     }
      //   ]
      // },
      {
        label: 'Office',
        icon: 'videocam',
        items: [
          {
            label: 'Excel',
            icon: 'videocam',
            items: [
              {
                label: 'Básico',
                icon: 'assignment',
                link: routes.VIDEOCURSO + '/5/0'
              },
              {
                label: 'Intermedio',
                icon: 'assignment',
                link: routes.VIDEOCURSO + '/6/0'
              },
              {
                label: 'Avanzado',
                icon: 'assignment',
                link: routes.VIDEOCURSO + '/7/0'
              },
              {
                label: 'Para Contadores',
                icon: 'assignment',
                link: routes.VIDEOCURSO + '/8/0'
              }
            ]
          },
          // {
          //   label: 'Word',
          //   icon: 'videocam',
          //   items: [
          //     {
          //       label: 'Básico',
          //       icon: 'assignment',
          //       link: routes.VIDEOCURSO + '/9/0'
          //     },
          //     {
          //       label: 'Intermedio',
          //       icon: 'assignment',
          //       link: routes.VIDEOCURSO + '/10/0'
          //     },
          //     {
          //       label: 'Avanzado',
          //       icon: 'assignment',
          //       link: routes.VIDEOCURSO + '/11/0'
          //     }
          //   ]
          // },
          // {
          //   label: 'Access',
          //   icon: 'videocam',
          //   items: [
          //     {
          //       label: 'Básico',
          //       icon: 'assignment',
          //       link: routes.VIDEOCURSO + '/12/0'
          //     },
          //     {
          //       label: 'Intermedio',
          //       icon: 'assignment',
          //       link: routes.VIDEOCURSO + '/13/0'
          //     },
          //     {
          //       label: 'Avanzado',
          //       icon: 'assignment',
          //       link: routes.VIDEOCURSO + '/14/0'
          //     }
          //   ]
          // },
          // {
          //   label: 'PowerPoint',
          //   icon: 'videocam',
          //   items: [
          //     {
          //       label: 'Básico',
          //       icon: 'assignment',
          //       link: routes.VIDEOCURSO + '/15/0'
          //     },
          //     {
          //       label: 'Intermedio',
          //       icon: 'assignment',
          //       link: routes.VIDEOCURSO + '/16/0'
          //     },
          //     {
          //       label: 'Avanzado',
          //       icon: 'assignment',
          //       link: routes.VIDEOCURSO + '/17/0'
          //     }
          //   ]
          // }
        ]
      },
      {
        label: 'Marketing',
        icon: 'videocam',
        // link: routes.VIDEOCURSO + '/18/0'
        items: [
          {
            label: 'Marketing',
            icon: 'assignment',
            link: routes.VIDEOCURSO + '/18/0'
          },
          {
            label: 'MKT - Tiktok',
            icon: 'assignment',
            link: routes.VIDEOCURSO + '/14/0'
          },
          {
            label: 'Canva - Curso',
            icon: 'assignment',
            link: routes.VIDEOCURSO + '/15/0'
          },
          {
            label: 'Edición - Capcut',
            icon: 'assignment',
            link: routes.VIDEOCURSO + '/16/0'
          },
          {
            label: 'Diseño Links Canva',
            icon: 'assignment',
            link: routes.VIDEOCURSO + '/17/0'
          },
        ]
      },
      {
        label: 'En Vivo',
        icon: 'videocam',
        link: routes.VIDEOCURSO + '/9/0'
      },
    ]
  },
  {
    label: 'Calculadoras',
    icon: 'calculate',
    items: [
      {
        label: '2019',
        icon: 'today',
        link: routes.CALCULADORACURSO + '/2019'
      },
      {
        label: '2020',
        icon: 'today',
        link: routes.CALCULADORACURSO + '/2020'
      },
      {
        label: '2021',
        icon: 'today',
        link: routes.CALCULADORACURSO + '/2021'
      },
      {
        label: '2022',
        icon: 'today',
        link: routes.CALCULADORACURSO + '/2022'
      },
      {
        label: '2023',
        icon: 'today',
        link: routes.CALCULADORACURSO + '/2023'
      },
      {
        label: '2024',
        icon: 'today',
        link: routes.CALCULADORACURSO + '/2024'
      }
    ]
  },
  {
    label: 'Herramientas',
    icon: 'save',
    items: [
      {
        label: 'Leyes',
        icon: 'auto_stories',
        items: [
          {
            label: '2021',
            icon: 'today',
            link: routes.LEYCURSO + '/2021'
          },
          {
            label: '2022',
            icon: 'today',
            link: routes.LEYCURSO + '/2022'
          },
          {
            label: '2023',
            icon: 'today',
            link: routes.LEYCURSO + '/2023'
          },
          {
            label: '2024',
            icon: 'today',
            link: routes.LEYCURSO + '/2024'
          }
        ]
      },
      {
        label: 'Libros',
        icon: 'menu_book',
        items: [
          {
            label: '2019',
            icon: 'today',
            link: routes.LIBROCURSO + '/2019'
          },
          {
            label: '2020',
            icon: 'today',
            link: routes.LIBROCURSO + '/2020'
          },
          {
            label: '2021',
            icon: 'today',
            link: routes.LIBROCURSO + '/2021'
          },
          {
            label: '2022',
            icon: 'today',
            link: routes.LIBROCURSO + '/2022'
          },
          {
            label: '2023',
            icon: 'today',
            link: routes.LIBROCURSO + '/2023'
          },
          {
            label: '2024',
            icon: 'today',
            link: routes.LIBROCURSO + '/2024'
          }
        ]
      },
      {
        label: 'Revistas',
        icon: 'import_contacts',
        items: [
          {
            label: '2019',
            icon: 'today',
            link: routes.REVISTACURSO + '/2019'
          },
          {
            label: '2020',
            icon: 'today',
            link: routes.REVISTACURSO + '/2020'
          },
          {
            label: '2021',
            icon: 'today',
            link: routes.REVISTACURSO + '/2021'
          },
          {
            label: '2022',
            icon: 'today',
            link: routes.REVISTACURSO + '/2022'
          },
          {
            label: '2023',
            icon: 'today',
            link: routes.REVISTACURSO + '/2023'
          },
          {
            label: '2024',
            icon: 'today',
            link: routes.REVISTACURSO + '/2024'
          }
        ]
      },
      {
        label: 'Software',
        icon: 'save',
        link: routes.SOFTWARECURSO,
      },
      {
        label: 'Contratos',
        icon: 'insert_drive_file',
        items: [
          {
            label: 'Fiscal',
            icon: 'insert_drive_file',
            items: [
              {
                label: 'Amparos fiscales',
                icon: 'assignment',
                link: routes.CONTRATOCURSO + '/1'
              },
              {
                label: 'Documentos emitidos por SAT',
                icon: 'assignment',
                link: routes.CONTRATOCURSO + '/2'
              },
              {
                label: 'Formularios IMSS',
                icon: 'assignment',
                link: routes.CONTRATOCURSO + '/3'
              },
              {
                label: 'Formularios SAT',
                icon: 'assignment',
                link: routes.CONTRATOCURSO + '/4'
              },
              {
                label: 'Incidentes',
                icon: 'assignment',
                link: routes.CONTRATOCURSO + '/5'
              },
              {
                label: 'Otros',
                icon: 'assignment',
                link: routes.CONTRATOCURSO + '/6'
              },
            ]
          },
          {
            label: 'Juicio Mercantil',
            icon: 'insert_drive_file',
            items: [
              {
                label: 'Demandas',
                icon: 'assignment',
                link: routes.CONTRATOCURSO + '/7'
              },
              {
                label: 'Escritos',
                icon: 'assignment',
                link: routes.CONTRATOCURSO + '/8'
              },
              {
                label: 'Otros',
                icon: 'assignment',
                link: routes.CONTRATOCURSO + '/9'
              },
            ]
          },
          {
            label: 'Laboral',
            icon: 'insert_drive_file',
            items: [
              {
                label: 'Formatos en Materia Laboral',
                icon: 'assignment',
                link: routes.CONTRATOCURSO + '/10'
              },
            ]
          },
          {
            label: 'Mercantil',
            icon: 'insert_drive_file',
            items: [
              {
                label: 'Contratos',
                icon: 'assignment',
                link: routes.CONTRATOCURSO + '/11'
              },
              {
                label: 'Otros Sociedades Mercantiles',
                icon: 'assignment',
                link: routes.CONTRATOCURSO + '/12'
              },
              {
                label: 'Quiebra',
                icon: 'assignment',
                link: routes.CONTRATOCURSO + '/13'
              },
              {
                label: 'Transformación fusión y escisión de sociedades mercantiles',
                icon: 'assignment',
                link: routes.CONTRATOCURSO + '/14'
              },
              {
                label: 'Constitución de sociedades mercantiles',
                icon: 'assignment',
                link: routes.CONTRATOCURSO + '/15'
              },
            ]
          },
          {
            label: 'Otros',
            icon: 'insert_drive_file',
            items: [
              {
                label: 'Contratos varios',
                icon: 'assignment',
                link: routes.CONTRATOCURSO + '/16'
              },
            ]
          },
        ]
      }
    ]
  },
  // {
  //   label: 'Mi Despacho',
  //   icon: 'calculate',
  //   items: [
  //     {
  //       label: 'Sección 1',
  //       icon: 'today',
  //       //link: routes.CALCULADORACURSO + '/2019'
  //     },
  //     {
  //       label: 'Sección 2',
  //       icon: 'today',
  //       //link: routes.CALCULADORACURSO + '/2020'
  //     },
  //     {
  //       label: 'Sección 3',
  //       icon: 'today',
  //       //link: routes.CALCULADORACURSO + '/2021'
  //     },
  //     {
  //       label: 'Sección 4',
  //       icon: 'today',
  //       //link: routes.CALCULADORACURSO + '/2022'
  //     },
  //   ]
  // },
  // {
  //   label: 'Leyes',
  //   icon: 'auto_stories',
  //   items: [
  //     {
  //       label: '2021',
  //       icon: 'today',
  //       link: routes.LEYCURSO + '/2021'
  //     },
  //     {
  //       label: '2022',
  //       icon: 'today',
  //       link: routes.LEYCURSO + '/2022'
  //     },
  //     {
  //       label: '2023',
  //       icon: 'today',
  //       link: routes.LEYCURSO + '/2023'
  //     },
  //     {
  //       label: '2024',
  //       icon: 'today',
  //       link: routes.LEYCURSO + '/2024'
  //     }
  //   ]
  // },
  // {
  //   label: 'Libros',
  //   icon: 'menu_book',
  //   items: [
  //     {
  //       label: '2019',
  //       icon: 'today',
  //       link: routes.LIBROCURSO + '/2019'
  //     },
  //     {
  //       label: '2020',
  //       icon: 'today',
  //       link: routes.LIBROCURSO + '/2020'
  //     },
  //     {
  //       label: '2021',
  //       icon: 'today',
  //       link: routes.LIBROCURSO + '/2021'
  //     },
  //     {
  //       label: '2022',
  //       icon: 'today',
  //       link: routes.LIBROCURSO + '/2022'
  //     },
  //     {
  //       label: '2023',
  //       icon: 'today',
  //       link: routes.LIBROCURSO + '/2023'
  //     },
  //     {
  //       label: '2024',
  //       icon: 'today',
  //       link: routes.LIBROCURSO + '/2024'
  //     }
  //   ]
  // },
  // {
  //   label: 'Revistas',
  //   icon: 'import_contacts',
  //   items: [
  //     {
  //       label: '2019',
  //       icon: 'today',
  //       link: routes.REVISTACURSO + '/2019'
  //     },
  //     {
  //       label: '2020',
  //       icon: 'today',
  //       link: routes.REVISTACURSO + '/2020'
  //     },
  //     {
  //       label: '2021',
  //       icon: 'today',
  //       link: routes.REVISTACURSO + '/2021'
  //     },
  //     {
  //       label: '2022',
  //       icon: 'today',
  //       link: routes.REVISTACURSO + '/2022'
  //     },
  //     {
  //       label: '2023',
  //       icon: 'today',
  //       link: routes.REVISTACURSO + '/2023'
  //     },
  //     {
  //       label: '2024',
  //       icon: 'today',
  //       link: routes.REVISTACURSO + '/2024'
  //     }
  //   ]
  // },
  // {
  //   label: 'Software',
  //   icon: 'save',
  //   link: routes.SOFTWARECURSO,
  // },
  // {
  //   label: 'Contratos',
  //   icon: 'insert_drive_file',
  //   items: [
  //     {
  //       label: 'Fiscal',
  //       icon: 'insert_drive_file',
  //       items: [
  //         {
  //           label: 'Amparos fiscales',
  //           icon: 'assignment',
  //           link: routes.CONTRATOCURSO + '/1'
  //         },
  //         {
  //           label: 'Documentos emitidos por SAT',
  //           icon: 'assignment',
  //           link: routes.CONTRATOCURSO + '/2'
  //         },
  //         {
  //           label: 'Formularios IMSS',
  //           icon: 'assignment',
  //           link: routes.CONTRATOCURSO + '/3'
  //         },
  //         {
  //           label: 'Formularios SAT',
  //           icon: 'assignment',
  //           link: routes.CONTRATOCURSO + '/4'
  //         },
  //         {
  //           label: 'Incidentes',
  //           icon: 'assignment',
  //           link: routes.CONTRATOCURSO + '/5'
  //         },
  //         {
  //           label: 'Otros',
  //           icon: 'assignment',
  //           link: routes.CONTRATOCURSO + '/6'
  //         },
  //       ]
  //     },
  //     {
  //       label: 'Juicio Mercantil',
  //       icon: 'insert_drive_file',
  //       items: [
  //         {
  //           label: 'Demandas',
  //           icon: 'assignment',
  //           link: routes.CONTRATOCURSO + '/7'
  //         },
  //         {
  //           label: 'Escritos',
  //           icon: 'assignment',
  //           link: routes.CONTRATOCURSO + '/8'
  //         },
  //         {
  //           label: 'Otros',
  //           icon: 'assignment',
  //           link: routes.CONTRATOCURSO + '/9'
  //         },
  //       ]
  //     },
  //     {
  //       label: 'Laboral',
  //       icon: 'insert_drive_file',
  //       items: [
  //         {
  //           label: 'Formatos en Materia Laboral',
  //           icon: 'assignment',
  //           link: routes.CONTRATOCURSO + '/10'
  //         },
  //       ]
  //     },
  //     {
  //       label: 'Mercantil',
  //       icon: 'insert_drive_file',
  //       items: [
  //         {
  //           label: 'Contratos',
  //           icon: 'assignment',
  //           link: routes.CONTRATOCURSO + '/11'
  //         },
  //         {
  //           label: 'Otros Sociedades Mercantiles',
  //           icon: 'assignment',
  //           link: routes.CONTRATOCURSO + '/12'
  //         },
  //         {
  //           label: 'Quiebra',
  //           icon: 'assignment',
  //           link: routes.CONTRATOCURSO + '/13'
  //         },
  //         {
  //           label: 'Transformación fusión y escisión de sociedades mercantiles',
  //           icon: 'assignment',
  //           link: routes.CONTRATOCURSO + '/14'
  //         },
  //         {
  //           label: 'Constitución de sociedades mercantiles',
  //           icon: 'assignment',
  //           link: routes.CONTRATOCURSO + '/15'
  //         },
  //       ]
  //     },
  //     {
  //       label: 'Otros',
  //       icon: 'insert_drive_file',
  //       items: [
  //         {
  //           label: 'Contratos varios',
  //           icon: 'assignment',
  //           link: routes.CONTRATOCURSO + '/16'
  //         },
  //       ]
  //     },
  //   ]
  // },

  {
    label: 'Soporte',
    icon: 'email',
    link: routes.SOPORTE,
  },
  {
    label: 'VS-Contabilidad',
    icon: 'assignment',
    link: routes.VSCONTABILIDAD,
  },
  {
    label: 'VS-Facturación',
    icon: 'assignment',
    link: routes.VSFACTURACION,
  },
  // {
  //   label: 'Catálogos',
  //   icon: 'folder',
  //   items: [
  //     {
  //       label: 'Usuarios',
  //       icon: 'assignment',
  //       link: routes.USUARIOS,
  //     },
  //     {
  //       label: 'Tipo cursos',
  //       icon: 'assignment',
  //       link: routes.CATTIPOCURSOS,
  //     },
  //     {
  //       label: 'Cursos',
  //       icon: 'assignment',
  //       link: routes.CATCURSOS,
  //     },
  //     {
  //       label: 'Calculadoras',
  //       icon: 'assignment',
  //       link: routes.CATCALCULADORAS,
  //     },
  //     {
  //       label: 'Leyes',
  //       icon: 'assignment',
  //       link: routes.CATLEYES,
  //     },
  //     {
  //       label: 'Libros',
  //       icon: 'assignment',
  //       link: routes.CATLIBROS,
  //     },
  //     {
  //       label: 'Revistas',
  //       icon: 'assignment',
  //       link: routes.CATREVISTAS,
  //     },
  //     {
  //       label: 'Software',
  //       icon: 'assignment',
  //       link: routes.CATSOFTWARE,
  //     },
  //     {
  //       label: 'Contratos',
  //       icon: 'assignment',
  //       link: routes.CATCONTRATOS,
  //     },
  //     {
  //       label: 'Alertas',
  //       icon: 'assignment',
  //       link: routes.CATALERTAS,
  //     }
  //   ]
  // },
  // {
  //   label: 'SAT',
  //   icon: 'folder',
  //   items: [
  //     {
  //       label: 'Solicitados',
  //       icon: 'assignment',
  //       link: routes.SATSOLICITADOS,
  //     },
  //     {
  //       label: 'Peticiones',
  //       icon: 'assignment',
  //       link: routes.SATPETICIONES,
  //     }
  //   ]
  // },
];
