import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root'
})
export class FuncionesTextoService {

  constructor() {}

  capitalizaPrimeraLetra(text: string) {
    return text.charAt(0).toUpperCase() + text.slice(1);
  }

}

    