export enum routes {
  LOGIN = '/login',
  INICIO = '/inicio',
  CONTRASENIA = 'usuarios/contrasenia/',
  CALCULADORAS = '/calculadoras',
  CURSOS = '/cursos',
  LIBROS = '/libros',
  REVISTAS = '/revistas',
  SOFTWARE = '/software',
  CONTRATOS = '/contratos',
  USUARIOS = '/usuarios',
  CATTIPOCURSOS = '/cat-tipo-cursos',
  SOPORTE = '/soporte',
  //Cátalogos
  CATCURSOS = '/cat-cursos',
  CATCALCULADORAS = '/cat-calculadoras',
  CATLEYES = '/cat-leyes',
  CATLIBROS = '/cat-libros',
  CATREVISTAS = '/cat-revistas',
  CATSOFTWARE = '/cat-software',
  CATCONTRATOS = '/cat-contratos',
  CATALERTAS = '/cat-alertas',
  //Cursos
  VIDEOCURSO = '/cat-cursos/curso-curso',
  CALCULADORACURSO = '/cat-calculadoras/calculadora-curso',
  LEYCURSO = '/cat-leyes/ley-curso',
  LIBROCURSO = '/cat-libros/libro-curso',
  REVISTACURSO = '/cat-revistas/revista-curso',
  SOFTWARECURSO = '/cat-software/software-curso',
  CONTRATOCURSO = '/cat-contratos/contrato-curso',
  //Sitios VS
  VSCONTABILIDAD = '/sitio-contabilidad',
  VSFACTURACION = '/sitio-facturacion',
  //SAT
  SATSOLICITADOS = '/sat/listado',
  SATPETICIONES = '/sat/solicitud',
}
