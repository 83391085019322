import { Paginador } from "../../../../../shared/utilities/models";

export class CursoFiltro {
    constructor(
        public columnaOrden: string = '',
        
        public tipoCursoId: number = 0,
        public filCursoId: number = 0,
        //public filCursoNombre: string = '',
        public filTitulo: string = '',
        public filDescripcion: string = '',
        public filTipoVideo: number = 0,
        public filAnio: number = 0,
        public filtraFilActivo: boolean = false,
        public filActivo: boolean = false,
        public datPag: Paginador = new Paginador()
    ) { }
}