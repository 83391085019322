<button class="notification-button" mat-mini-fab [matMenuTriggerFor]="bell">
  <mat-icon [matBadge]="numAlertas" matBadgeColor="accent" class="notification-button__icon">notifications_none</mat-icon>
</button>
<mat-menu #bell="matMenu" xPosition="before" class="notification-menu">
    <div *ngFor="let alerta of alertas">
        <button type="button" mat-menu-item [ngClass]="{'notification-menu__button-pending': !alerta.leido, 'notification-menu__button-read': alerta.leido}" (click)="mostrarAlerta(alerta)">
          <mat-icon class="notification-menu__icon_pink">notifications_none</mat-icon>
          {{ alerta.titulo }}
        </button>
    </div>
</mat-menu>