import { Injectable } from '@angular/core';
import {
    HttpEvent,
    HttpInterceptor,
    HttpHandler,
    HttpRequest,
    HttpHeaders
} from '@angular/common/http';
import { Observable } from 'rxjs';
import { LocalStorageService } from '../services';
import { SpinneService } from '../services/spinner.service';
import { finalize } from 'rxjs/operators';

@Injectable()
export class AuthInterceptor implements HttpInterceptor {
    
    constructor(
        private _localStorageService: LocalStorageService,
        private _spinnerService: SpinneService
    ) {}

    intercept(
        req: HttpRequest<any>,
        next: HttpHandler
    ): Observable<HttpEvent<any>> {
        /*if (req.url.indexOf('/PubSeguridad') > 0) {
            const authReqN = req.clone({
                headers: req.headers
                    .set('Content-Type', 'application/json')
                    .set('Accept', 'application/json')
            });

            return next.handle(authReqN);
        }*/
        this._spinnerService.iniciarSpinner();

        let authHeader = `Bearer ${this._localStorageService.obtenerTokenSesion()}`;
        
        //PETICIONES CUANDO SE SUBE ARCHIVO A SERVIDOR
        if (req.url.indexOf('/SubeArchivoBd') > 0) {
            const authReqN = req.clone({
                headers: req.headers
                    .set('Authorization', authHeader)
                    .set('enctype', 'multipart/form-data')
            });

            return next.handle(authReqN);
        }
        //PETICIONES GENERALES
        const authReq = req.clone({
            headers: req.headers
                .set('Authorization', authHeader)
                .set('Content-Type', 'application/json')
        });
        
        return next.handle(authReq).pipe(
            finalize(() => this._spinnerService.detenerSpinner())
        );
    }
}
