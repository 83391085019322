import { DatosAuditoria } from '../../../../../../shared/utilities/models';

export class Usuario extends DatosAuditoria {
    constructor (
        public establecimientoId: number = 0,
        public usuarioId: number = 0,
        public usuario: string = '',
        public contrasenia: string = '',
        public claveAutorizacion: string = '',
        public perfilId: number = 0,
        public nombre: string = '',
        public apellidoPaterno: string = '',
        public apellidoMaterno: string = '',
        public correoElectronico: string = '',
        public fecUltCambioContrasenia: string = '1800-01-01',
        public fecUltCambioClaveAutorizacion: string = '1800-01-01',
        public activo: boolean = true,
        //Calculados
        public establecimientoNombre: string = '',
        public perfilNombre: string = ''
    ) {
        super();
    }
}