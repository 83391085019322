import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
//rxjs
import { Observable } from 'rxjs';
//App
import { url_bases } from "../../../../../consts";
import { routes } from '../consts';
import { AlertaPagina, AlertaFiltro, Alerta } from '../models';
import { AlertaUsuario } from '../models/alerta-usuario';

@Injectable({
  providedIn: 'root'
})
export class AlertaService {

  constructor(private _httpClient: HttpClient) {}

  public alertasPag(alertaFiltro: AlertaFiltro): Observable<AlertaPagina> {
    return this._httpClient.post<AlertaPagina>(
      url_bases.URL_CLIENTEAPI + routes.URL_BASE + routes.URL_CONSULTA_PAGINADA,
      alertaFiltro
    );
  }

  public alertasXUsuarioId(usuarioId: number): Observable<Alerta[]> {
    return this._httpClient.get<Alerta[]>(
      url_bases.URL_CLIENTEAPI + routes.URL_BASE + routes.URL_CONSULTA_X_USUARIO + '/' + usuarioId
    );
  }

  public alertaXId(id: number): Observable<Alerta> {
    return this._httpClient.get<Alerta>(
      url_bases.URL_CLIENTEAPI + routes.URL_BASE + routes.URL_CONSULTA_X_ID + '/' + id
    );
  }

  public alertaInserta(alerta: Alerta): Observable<any> {
    return this._httpClient.post<any>(
      url_bases.URL_CLIENTEAPI + routes.URL_BASE + routes.URL_INSERTA,
      alerta
    );
  }

  public alertaActualiza(alerta: Alerta): Observable<any> {
    return this._httpClient.post<any>(
      url_bases.URL_CLIENTEAPI + routes.URL_BASE + routes.URL_ACTUALIZA,
      alerta
    );
  }

  public alertaElimina(alerta: Alerta): Observable<any> {
    return this._httpClient.post<any>(
      url_bases.URL_CLIENTEAPI + routes.URL_BASE + routes.URL_ELIMINA,
      alerta
    );
  }
  
  public alertaUsuarioInserta(alertaUsuario: AlertaUsuario): Observable<any> {
    return this._httpClient.post<any>(
      url_bases.URL_CLIENTEAPI + routes.URL_BASE + routes.URL_REVISADA,
      alertaUsuario
    );
  }
}

    