import { Component, OnInit } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
//app
import { routes } from "../../consts";
import { Curso, TipoVideoCurs } from '../../models';
import { CursoService } from '../../services';
import { MensajeService } from '../../../../../../shared/utilities/components/mensaje/mensaje.service';

@Component({
  selector: 'app-curso-captura',
  templateUrl: './curso-captura.component.html',
  styleUrls: ['./curso-captura.component.scss']
})
export class CursoCapturaComponent implements OnInit {
  public capturaFG: FormGroup;
  public ctrlHabilitado: boolean = false;
  public curso = new Curso();
  public titulo: string = '';
  public tiposVideo: any[] = [];
  public anios = [2018, 2019, 2020, 2021, 2022, 2023, 2024, 2025, 2026, 2027, 2028, 2029, 2030];
  public archivoAceptado = 'zip';
  public archivoCargado: any;

  constructor(
    private _formBuilder: FormBuilder,
    private _router: Router,
    private _activatedRoute: ActivatedRoute,
    private _cursoService: CursoService,
    private _mensajeService: MensajeService,
  ) { }

  ngOnInit(): void {
    for (const item in TipoVideoCurs) {
      if (isNaN(Number(item))) {
        this.tiposVideo.push({ text: item, value: TipoVideoCurs[item] });
      }
    }

    this.curso = this._activatedRoute.snapshot.data.curso;
    let accion = parseInt(this._activatedRoute.snapshot.paramMap.get('accion'));

    switch (accion) {
      case 1: this.titulo = 'Agregar'; break;
      case 2: this.titulo = 'Modificar'; break;
      case 3: this.titulo = 'Consultar'; break;
      default: break;
    }

    this.ctrlHabilitado = (accion === 3) ? true : false; //ConsultaId

    this.creaFormGroup();
    this.estableceForm();
  }

  creaFormGroup() {
    this.capturaFG = this._formBuilder.group({
      tipoCursoId: [0, [Validators.required, Validators.min(1)]],
      cursoId: [0, [Validators.required, Validators.min(0)]],
      orden: [0, [Validators.required, Validators.min(0)]],
      //cursoNombre: ['', [Validators.required, Validators.minLength(2), Validators.maxLength(200)]],
      titulo: ['', [Validators.required, Validators.minLength(2), Validators.maxLength(200)]],
      descripcion: ['', [Validators.required, Validators.minLength(2), Validators.maxLength(200)]],
      linkCurso: ['', [Validators.minLength(2), Validators.maxLength(200)]],
      linkMaterialCurso: ['', [Validators.minLength(2), Validators.maxLength(200)]],
      tipoVideo: ['', [Validators.required, Validators.min(1)]],
      anio: ['0', [Validators.required]],
      activo: [true, [Validators.required]]
    });
  }

  obtenerForm() {
    let objForm = this.capturaFG.getRawValue();

    this.curso.tipoCursoId = parseInt(objForm.tipoCursoId);
    this.curso.cursoId = parseInt(objForm.cursoId);
    this.curso.orden = parseInt(objForm.orden);
    //this.curso.cursoNombre = objForm.cursoNombre;
    this.curso.titulo = objForm.titulo;
    this.curso.descripcion = objForm.descripcion;
    this.curso.linkCurso = objForm.linkCurso;
    this.curso.linkMaterialCurso = objForm.linkMaterialCurso;
    this.curso.tipoVideo = objForm.tipoVideo;
    this.curso.anio = objForm.anio;
    this.curso.activo = objForm.activo;
  }

  estableceForm() {
    this.curso.anio = (this.curso.anio === 0) ? (new Date).getFullYear() : this.curso.anio;
    this.capturaFG.patchValue({
      tipoCursoId: this.curso.tipoCursoId.toString(),
      cursoId: this.curso.cursoId.toString(),
      orden: this.curso.orden.toString(),
      //cursoNombre: this.curso.cursoNombre,
      titulo: this.curso.titulo,
      descripcion: this.curso.descripcion,
      linkCurso: this.curso.linkCurso,
      linkMaterialCurso: this.curso.linkMaterialCurso,
      tipoVideo: this.curso.tipoVideo,
      anio: this.curso.anio,
      activo: this.curso.activo
    });
  }

  eventoArchivo(evtArchivo: any) {
    this.archivoCargado = evtArchivo;

    if (this.curso.linkMaterialCurso === '') {
      this.capturaFG.patchValue({linkMaterialCurso: evtArchivo.name});
    }

    if (this.curso.cursoId > 0) {
      this.capturaFG.patchValue({linkMaterialCurso: this.curso.cursoId + '_' + evtArchivo.name});
    }
  }

  obtenerErrores(controlName: string): string {
    let error = '';
    const control = this.capturaFG.get(controlName);

    if (control.touched && control.errors != null) {
      if (control.errors.required) {
        error = ': es requerido.'
      } else if (control.errors.minlength) {
        error = `: debe tener mínimo ${control.errors.minlength.requiredLength} carcateres.`
      } else if (control.errors.maxlength) {
        error = `: debe tener máximo ${control.errors.maxlength.requiredLength} carcateres.`
      } else if (control.errors.email) {
        error = ': formato invalido.'
      } else if (control.errors.min) {
        error = 'Debe seleccionar.'
      }
    }

    return error;
  }

  guardar() {
    let opciones = {
      titulo: 'Guardar',
      mensaje: 'Operación exitosa.',
      btnAceptar: 'Cerrar'
    };

    if (!this.capturaFG.valid) {
      alert('No se cumplio alguna regla de validación');
      return;
    }

    this.obtenerForm();

    if (this.curso.cursoId === 0) { //Inserta
      if (this.archivoCargado === undefined) {
        opciones.mensaje = 'No ha seleccionado un archivo';
        this._mensajeService.open(opciones);
        return;
      }

      this._cursoService.cursoInserta(this.curso, this.archivoCargado).subscribe(
        result => {
          this._mensajeService.open(opciones);
          this.regresar();
        },
        error => {
          opciones.mensaje = error.error
          this._mensajeService.open(opciones);
        },
      );

    } else if (this.curso.cursoId > 0) { //Actualiza

      this._cursoService.cursoActualiza(this.curso, this.archivoCargado).subscribe(
        result => {
            this._mensajeService.open(opciones);
            this.regresar();
        },
        error => {
          opciones.mensaje = error.error
          this._mensajeService.open(opciones);
        },
      );

    }
  }

  regresar() {
    this._router.navigate([routes.CONSULTA]);
  }
}
