<mat-card class="form-wrapper">
    <mat-card-title class="form-wrapper__header">
        <p class="form-wrapper__title">{{ titulo }}</p>
    </mat-card-title>
    <mat-card-content class="form-body">
        <form [formGroup]="capturaFG" (ngSubmit)="guardar()" class="form-body__content" appearance="fill">
            <!-- cursoId -->
            <mat-form-field class="form-body__input">
                <mat-label>Id</mat-label>
                <input matInput formControlName="cursoId" id="cursoId" class="form-body__input__border" [disableControl]="true">
            </mat-form-field>
            <!-- tipoCursoId -->
            <mat-form-field class="form-body__select">
                <!-- Tipo curso -->
                <mat-select formControlName="tipoCursoId" id="tipoCursoId" [disableControl]="ctrlHabilitado">
                    <mat-option value="0">Seleccione tipo curso</mat-option>
                    <mat-option value="1">Contabilidad</mat-option>
                    <!-- <mat-option value="2">CONTPAQi Bancos</mat-option>
                    <mat-option value="3">CONTPAQi Contabilidad Cierre y saldos iniciales</mat-option>
                    <mat-option value="4">CONTPAQi Taller Ajuste al subsidio e ISR anual</mat-option> -->
                    <mat-option value="5">Excel Básico</mat-option>
                    <mat-option value="6">Excel Intermedio</mat-option>
                    <mat-option value="7">Excel Avanzado</mat-option>
                    <mat-option value="8">Excel para Contadores</mat-option>
                    <mat-option value="9">En vivo</mat-option>
                    <!-- <mat-option value="10">Word Intermedio</mat-option>
                    <mat-option value="11">Word Avanzado</mat-option>
                    <mat-option value="12">Access Básico</mat-option>
                    <mat-option value="13">Access Intermedio</mat-option> -->
                    <mat-option value="14">MKT - Tiktok</mat-option>
                    <mat-option value="15">Canva - Curso</mat-option>
                    <mat-option value="16">Edición - Capcut</mat-option>
                    <mat-option value="17">Diseño Links Canva</mat-option>
                    <mat-option value="18">Marketing</mat-option>
                    <mat-option value="19">Talleres-Contables</mat-option>
                </mat-select>
            </mat-form-field>
            <br/>
            <!-- orden -->
            <mat-form-field class="form-body__input">
                <mat-label>
                    Orden{{ obtenerErrores('orden') }}
                </mat-label>
                <input matInput formControlName="orden" id="orden" class="form-body__input__border" [disableControl]="ctrlHabilitado">
            </mat-form-field>
            <!-- cursoNombre -->
            <!-- <mat-form-field class="form-body__input">
                <mat-label>Nombre{{ obtenerErrores('cursoNombre') }}</mat-label>
                <input matInput formControlName="cursoNombre" id="cursoNombre" class="form-body__input__border" [disableControl]="ctrlHabilitado">
            </mat-form-field> -->
            <!-- titulo -->
            <mat-form-field class="form-body__input">
                <mat-label>Título{{ obtenerErrores('titulo') }}</mat-label>
                <input matInput formControlName="titulo" id="titulo" class="form-body__input__border" [disableControl]="ctrlHabilitado">
            </mat-form-field>
            <!-- descripcion -->
            <mat-form-field class="form-body__input">
                <mat-label>Descripción{{ obtenerErrores('descripcion') }}</mat-label>
                <input matInput formControlName="descripcion" id="descripcion" class="form-body__input__border" [disableControl]="ctrlHabilitado">
            </mat-form-field>
            <!-- linkCurso -->
            <mat-form-field class="form-body__input">
                <mat-label>Link curso{{ obtenerErrores('linkCurso') }}</mat-label>
                <input matInput formControlName="linkCurso" id="linkCurso" class="form-body__input__border" [disableControl]="ctrlHabilitado">
            </mat-form-field>
            <!-- linkMaterialCurso -->
            <mat-form-field class="form-body__input">
                <mat-label>Link material{{ obtenerErrores('linkMaterialCurso') }}</mat-label>
                <input matInput formControlName="linkMaterialCurso" id="linkMaterialCurso" class="form-body__input__border" [disableControl]="ctrlHabilitado">
            </mat-form-field>
            <!-- tipoVideo -->
            <mat-form-field class="form-body__select">
                Tipo video
                <mat-select formControlName="tipoVideo" id="tipoVideo" [disableControl]="ctrlHabilitado">
                    <mat-option *ngFor="let tipo of tiposVideo" [value]="tipo.value">{{ tipo.text }}</mat-option>
                </mat-select>
            </mat-form-field>
            <!-- anio -->
            <mat-form-field class="form-body__select">
                Año
                <mat-select formControlName="anio" id="anio" [disableControl]="ctrlHabilitado">
                    <mat-option value="0">Seleccione año</mat-option>
                    <mat-option *ngFor="let anio of anios" [value]="anio">{{ anio }}</mat-option>
                </mat-select>
            </mat-form-field>
            <!-- activo -->
            <mat-checkbox formControlName="activo" id="activo" class="form-body__checkbox" [disableControl]="ctrlHabilitado">Activo</mat-checkbox>
            <!-- linkMaterialCurso -->
            <app-carga-archivo [archivoAceptado]="archivoAceptado" (eventoArchivo)="eventoArchivo($event)"></app-carga-archivo>

            <!-- Acciones -->
            <div class="form-body__actions">
                <button class="form-body__actions__login" mat-raised-button color="basic" type="button" (click)="regresar()">Regresar</button>
                <button class="form-body__actions__login" mat-raised-button color="primary" type="submit" [disabled]="capturaFG.invalid" [visibleControl]="!ctrlHabilitado">Guardar</button>
            </div>

        </form>
    </mat-card-content>
</mat-card>
