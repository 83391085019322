import { Component, Input } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import * as fileSaver from 'file-saver';
import { CursoVideoDialogComponent } from '../curso-video-dialog/curso-video-dialog.component';
import { VideoApp } from '../../../../../../shared/utilities/models/video-app';
import { url_bases } from '../../../../../../consts'
import { routes } from '../../consts'
import { Curso } from '../../models';
import { CursoService } from '../../services/curso.service';

@Component({
  selector: 'app-curso-video-card',
  templateUrl: './curso-video-card.component.html',
  styleUrls: ['./curso-video-card.component.scss']
})

export class CursoVideoCardComponent {
  @Input() curso: Curso;

  constructor(
    public _dialog: MatDialog,
    private _cursoService: CursoService) { }

  public abrirVideo(): void {
    let videoApp = new VideoApp();
    videoApp.id = this.curso.cursoId;
    videoApp.titulo = this.curso.titulo;
    videoApp.descripcion = this.curso.descripcion;
    videoApp.idVideo = this.curso.linkCurso;
    videoApp.tipoVideo = this.curso.tipoVideo;
    
    const dialogRef = this._dialog.open(CursoVideoDialogComponent, {
      data: videoApp,
      disableClose: true,
      //height: '80%',
      width: '50%'
    });
    /*dialogRef.afterClosed().subscribe(result => {
      console.log(`Dialog result: ${result}`);
    });*/
  }

  descarga() {
    var link = document.createElement('a');
    link.href = url_bases.URL_ARCHIVOS + 'ECurso/' + this.curso.linkMaterialCurso;
    link.target = '_blank';
    document.body.appendChild(link);  
    link.click();  
    document.body.removeChild(link);
    /* this._cursoService.cursoDescargaXId(this.curso).subscribe(
      (response: any) => {
        let blob: any = new Blob([response], { type: 'application/x-7z-compressed' });
        fileSaver.saveAs(blob, this.curso.linkMaterialCurso);
        
      }),
      (error: any) => console.log('Error downloading the file'),
      () => console.info('File downloaded successfully'); */
  }
}
