<div class="filter-wrapper">
    <p *ngIf="!muestraFiltros" class="filter-wrapper__title">Listado de cursos</p>
    <div *ngIf="muestraFiltros" class="filter-wrapper__search">
        <p class="filter-wrapper__title">Filtros</p>

        <form [formGroup]="filtroFormGroup" (ngSubmit)="obtenerFiltro(filtroFormGroup.value)">

            <!-- tipoCursoId -->
            <mat-form-field class="form-body__select">
                <mat-select formControlName="tipoCursoId" id="tipoCursoId">
                    <mat-option value="0">Seleccione perfíl</mat-option>
                    <mat-option value="1">Tipo 1</mat-option>
                    <mat-option value="2">Tipo 2</mat-option>
                    <mat-option value="3">Tipo 3</mat-option>
                </mat-select>
            </mat-form-field>
            <!-- filCursoId -->
            <mat-form-field class="filter-wrapper__search-input">
                <mat-label>Curso Id</mat-label>
                <input matInput formControlName="filCursoId" class="filter-wrapper__search-input__border">
            </mat-form-field>
            <!-- filCursoNombre -->
            <!-- <mat-form-field class="filter-wrapper__search-input">
                <mat-label>Nombre curso</mat-label>
                <input matInput matInput formControlName="filCursoNombre" class="filter-wrapper__search-input__border">
            </mat-form-field> -->
            <!-- filTitulo -->
            <mat-form-field class="filter-wrapper__search-input">
                <mat-label>Título</mat-label>
                <input matInput matInput formControlName="filTitulo" class="filter-wrapper__search-input__border">
            </mat-form-field>
            <!-- filDescripcion -->
            <mat-form-field class="filter-wrapper__search-input">
                <mat-label>Descripción</mat-label>
                <input matInput matInput formControlName="filDescripcion" class="filter-wrapper__search-input__border">
            </mat-form-field>
            <!-- filTipoVideo -->
            <mat-form-field class="filter-wrapper__select">
                <mat-select formControlName="filTipoVideo" id="filTipoVideo">
                    <mat-option *ngFor="let tipo of tiposVideo" [value]="tipo.value">{{ tipo.text }}</mat-option>
                </mat-select>
            </mat-form-field>
            <!-- anio -->
            <mat-form-field class="form-body__select">
                <mat-select formControlName="filAnio" id="filAnio">
                    <mat-option value="0">Seleccione año</mat-option>
                    <mat-option *ngFor="let anio of anios" [value]="anio">{{ anio }}</mat-option>
                </mat-select>
            </mat-form-field>
            <!-- filActivo -->
            <mat-checkbox formControlName="filActivo" class="filter-wrapper__checkbox">Activo</mat-checkbox>

            <button type="submit" mat-raised-button color="accent" class="filter-wrapper__button-fil" matTooltip="Filtrar">
                <mat-icon class="filter-wrapper__icon">filter_alt</mat-icon>
            </button>
            <button mat-raised-button color="accent" class="filter-wrapper__button-fil" (click)="limpiaFormFiltros(filtroFormGroup)" matTooltip="Limpiar">
                <mat-icon class="filter-wrapper__icon">clear</mat-icon>
            </button>
            <button mat-raised-button color="accent" class="filter-wrapper__button-fil" (click)="muestraFormFiltros()" matTooltip="Ocultar">
                <mat-icon class="filter-wrapper__icon">search_off</mat-icon>
            </button>
        </form>
    </div>

    <button class="filter-wrapper__button" mat-mini-fab (click)="muestraFormFiltros()">
        <mat-icon class="filter-wrapper__icon">search</mat-icon>
    </button>
</div>