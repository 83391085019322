<mat-toolbar class="header">

    <div class="header__title">
        <button class="header__title-button" mat-mini-fab (click)="openMenu()">
            <mat-icon *ngIf="!isMenuOpened" class="header__title-button-icon">menu</mat-icon>
            <mat-icon *ngIf="isMenuOpened" class="header__title-button-icon">arrow_back</mat-icon>
        </button>
        <!-- <h6 class="header__title-text">J & R Asesores Contables</h6> -->
        <img class="header__logo-img" src="../../../../../assets/auth/logocc.png" alt="logo">
    </div>

    <div class="header-toolbar">
        <!-- <app-twitter></app-twitter> -->
        <app-notifications></app-notifications>
        <app-user [usuarioSesion]="usuarioSesion" (terminarSesion)="terminarSesion()"></app-user>
    </div>

</mat-toolbar>